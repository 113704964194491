import { combineReducers } from "redux";
import { CLEAR_ALL } from "../actions/actionList";
import confirmationDialogReducer, {
  ConfirmationDialogState,
} from "./confirmationDialog"; // Add this line
import businessReducer, { BusinessState } from "./business";
import userReducer, { UserReducerState } from "./user";
import productReducer, { ProductReducerState } from "./product";
import publicReducer, { PublicReducerState } from './public';
export interface RootReducerState {
  confirmationDialog: ConfirmationDialogState;
  businessReducer: BusinessState;
  userReducer: UserReducerState;
  productReducer: ProductReducerState;
  publicReducer: PublicReducerState;
}

const appReducer = combineReducers({
  confirmationDialog: confirmationDialogReducer,
  businessReducer,
  userReducer,
  productReducer,
  publicReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === CLEAR_ALL) {
    const { publicReducer, ...restState } = state || {};
    localStorage.clear();
    return appReducer({
      ...restState,
      publicReducer 
    }, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
