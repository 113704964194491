import { Theme } from '@emotion/react';
import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';
import { COLOR } from '../../utils/color';

interface TemplateButtonPropsI {
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  buttonText: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};


const BtnSx: SxProps<Theme> = {
  width: '100%', 
  display: 'flex', 
  justifyContent:'center', 
  alignItems:'center',
  gap: 1,
  border: 'none',
  p: 2,
  borderRadius: 2,
  cursor: 'pointer',
  transition: 'all 0.5s ease',
}

const TemplateButton = (props: TemplateButtonPropsI) => {
  return (
    <Box
      component="button"
      disabled={props?.disabled || false}
      onClick={props?.onClick}
      sx={{
        ...BtnSx,
        backgroundColor: props?.backgroundColor || COLOR.primary50,
        color: props?.color || 'black',
        border: `1px solid ${props?.borderColor || props?.backgroundColor || COLOR.primary50}`,
        ...(props?.sx || {}),
      }}
    >
      <Typography fontWeight={600}>{props.buttonText}</Typography>
    </Box>
  )
};

export default TemplateButton;