import { Box, Skeleton, Typography } from "@mui/material";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import { useMemo } from "react";
import { useGetProductCompactList } from "../../query/queries";
import { RootReducerState } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { APIGetProductCompact } from "../../api/request.types";
import { COLOR } from "../../utils/color";
import CustomButton from "../../components/CustomButton/CustomButton";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../../utils/constant";
import { useDeleteProductById } from "../../query/mutations";
import { openDialog } from "../../redux/reducers/confirmationDialog";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../../utils/logger";

const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );

  const productCompactListQuery = useGetProductCompactList(
    userReducer.data.companyId || "",
    {
      refetchOnMount: true,
    }
  );

  const productList: APIGetProductCompact[] = useMemo(
    () => productCompactListQuery.data?.data?.data || [],
    [productCompactListQuery.data]
  );

  const deleteProductByIdMutation = useDeleteProductById();

  const handleDeleteProduct = (productData: APIGetProductCompact) => {
    dispatch(
      openDialog({
        message: `Apakah anda yakin untuk menghapus "${productData.productName}"?`,
        secondaryBtn: {
          onClick: async () => {
            try {
              await deleteProductByIdMutation.mutateAsync({
                productId: productData.productID,
              });
              await productCompactListQuery.refetch();
              enqueueSnackbar(
                `Product "${productData.productName}" deleted successfully`,
                {
                  variant: "success",
                  ...DEFAULT_SNACKBAR_PROPS,
                }
              );
            } catch (error) {
              errorLogger(error);
              enqueueSnackbar(
                "Gagal menghapus product. mohon coba beberapa saat lagi.",
                {
                  ...DEFAULT_SNACKBAR_PROPS,
                  variant: "error",
                }
              );
            }
          },
        },
      })
    );
  };

  const isLoading =
    productCompactListQuery.isLoading || deleteProductByIdMutation.isLoading;

  const _renderLoading = () =>
    Array.from({ length: 10 }).map((_, idx) => (
      <Skeleton key={idx} height="68px" sx={{ transform: "none" }} />
    ));

  const _renderProductList = () =>
    productList.map((product) => (
      <Box
        key={product.productID}
        display="flex"
        flexDirection="row"
        border={`1px solid ${COLOR.neutral300}`}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={2}
        p={1}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            fontWeight={500}
            color={COLOR.neutral500}
            variant="caption"
          >
            ID: {product.productID}
          </Typography>
          <Typography fontWeight={600}>{product.productName}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <CustomButton
            onClick={() =>
              navigate(
                `${RoutePath[ROUTE_NAME.PRODUCT]}/${product.productID}/edit`
              )
            }
            variant="text"
            sx={{
              width: "fit-content",
              height: "fit-content",
              backgroundColor: COLOR.primary50,
              aspectRatio: 1,
              minWidth: "50px",
              px: 0,
            }}
          >
            <FaPencilAlt size={20} />
          </CustomButton>
          <CustomButton
            onClick={() => handleDeleteProduct(product)}
            variant="text"
            sx={{
              width: "fit-content",
              height: "fit-content",
              aspectRatio: 1,
              minWidth: "50px",
              px: 0,
              backgroundColor: COLOR.danger50,
              color: COLOR.danger500,
              "&:hover": { backgroundColor: COLOR.danger50 },
              "&:active": { backgroundColor: COLOR.danger50 },
            }}
          >
            <FaTrash size={20} />
          </CustomButton>
        </Box>
      </Box>
    ));

  return (
    <MobileContainer
      additionalBottomChildren={
        <Box position="relative">
          <Box
            onClick={() => navigate(RoutePath[ROUTE_NAME.ADD_PRODUCT])}
            sx={{
              mt: 2,
              width: "60px",
              boxShadow: 5,
              position: "absolute",
              aspectRatio: 1,
              borderRadius: "50%",
              bottom: 20,
              right: 20,
              backgroundColor: COLOR.primary500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: "white",
              "&:hover, &:active": { backgroundColor: COLOR.primary400 },
            }}
          >
            <FaPlus />
          </Box>
        </Box>
      }
    >
      <Box height="100%" display="flex" flexDirection="column">
        <SubMenuHeader
          leftNav={{ icon: <FaArrowLeft /> }}
          text="Product List"
        />
        <Box p={2} display="flex" flexDirection="column" height="100%" pb={10}>
          <Typography>Jumlah Produk: {productList.length}</Typography>
          <Box display="flex" flexDirection="column" gap={1} mt={1}>
            {isLoading ? _renderLoading() : _renderProductList()}
          </Box>
        </Box>
      </Box>
    </MobileContainer>
  );
};

export default ProductList;
