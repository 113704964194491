import { SxProps, Theme } from "@mui/material";
import { MAX_PAGE_WIDTH } from "../../../utils/constant";
import { COLOR } from "../../../utils/color";

export const OuterContainerStyle: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  overflowY: "scroll",
  backgroundColor: COLOR.neutral100,
};

export const InnerContainerStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  maxWidth: MAX_PAGE_WIDTH,
  backgroundColor: "inherit",
  overflowY: 'scroll',
  boxShadow: 2,
};

export const NavContainerStyle: SxProps<Theme> = {
  position: "fixed",
  bottom: 0,
  alignSelf: "center",
  maxWidth: MAX_PAGE_WIDTH,
  backgroundColor: 'white',
};
