import { CSSProperties, useEffect } from "react";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { useDynamicPage } from "../../NavigateOnValidCompanyName";
import ImageCarousel from "../../../components/ImageCarousel/ImageCarousel";
import { Box, Typography } from "@mui/material";
import ShowMoreByWords from "../../../components/ShowMore/ShowMoreByWords";
import { generateGoogleMapSrc } from "../../../utils/helper";
import LocationIcon from "../../../assets/svg/LocationIcon";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { COLOR } from "../../../utils/color";
import { FooterContainer } from "./Template1.styles";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_NAME, RoutePath } from "../../../utils/constant";
import { useGetPublicProductById } from "../../../query/queries";
import isEmpty from "lodash.isempty";
import { GetPublicProductByIdResponse } from "../../../api/request.types";
import useNavigateDynamicPage from "../../../hooks/useNavigateDynamicPage";

const aspectRatio = 9 / 16;

const iframeStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  border: "0",
};
const DEFAULT_PRODUCT_DETAIL: GetPublicProductByIdResponse = {
  _id: "",
  name: "",
  description: "",
  duration: 0,
  durationPeriod: "",
  location: {
    mapLocation: "",
    completeAddress: "",
    useManualLocation: false,
    longitude: 0,
    latitude: 0,
    timezone: "",
  },
  photos: [],
  photosSignedURL: [],
  price: 0,
  productID: "",
};
const DProduct = () => {
  const { data } = useDynamicPage();
  const navigate = useNavigateDynamicPage();
  const { productId } = useParams<{ productId: string }>();
  const getPublicProductById = useGetPublicProductById(productId || "", {
    retry: false,
    enabled: !isEmpty(productId),
  });

  const productData: GetPublicProductByIdResponse =
    getPublicProductById.data?.data?.data || DEFAULT_PRODUCT_DETAIL;
  useEffect(() => {
    if (!productId) {
      // navigate(RoutePath[ROUTE_NAME.DYNAMIC_HOME], { replace: true });
    }
  }, [navigate, productId]);

  if (!productId) {
    return null;
  }

  return (
    <AppContainer
      bottomChildren={
        <Box sx={FooterContainer}>
          {/* <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Typography>Rp {formatNumber(productData.price)}</Typography>
            <Typography>
              per {data.duration} {data.durationUnit}
            </Typography>
          </Box> */}
          <CustomButton
            sx={{ height: "fit-content" }}
            onClick={() => {
              if (!isEmpty(productData.productID))
                navigate(
                  RoutePath[ROUTE_NAME.SCHEDULE] + `/${productData.productID}`
                );
            }}
          >
            <Typography fontWeight={600} variant="body1">
              Pilih Jadwal
            </Typography>
          </CustomButton>
        </Box>
      }
    >
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
        }}
        text={"Detail Produk"}
      />
      <ImageCarousel
        images={productData.photosSignedURL}
        bulletColor={data.color}
        activeBulletColor={data.color}
      />
      <Box sx={{ pb: 5 }}>
        <Box sx={{ px: 2 }}>
          <Typography variant="h6" fontWeight={700} mt={2}>
            {productData.name}
          </Typography>

          <Typography variant="h6" fontWeight={600} mt={3}>
            Deskripsi
          </Typography>
          <Typography mt={1}>
            <ShowMoreByWords text={productData.description} />
          </Typography>
          <Typography variant="h6" fontWeight={600} mt={3}>
            Lokasi
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingBottom: `${aspectRatio * 100}%`,
              height: 0,
              borderRadius: 2,
              overflow: "hidden",
              mt: 1,
            }}
          >
            <iframe
              style={iframeStyle}
              src={generateGoogleMapSrc({
                address: productData.location.mapLocation,
                latitude: productData.location.latitude,
                longitude: productData.location.longitude,
              })}
              title={productData.location.completeAddress}
              allowFullScreen
            />
          </Box>
        </Box>
        <OpacityButton
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            px: 2,
            py: 1,
            mt: 1,
            color: COLOR.neutral500,
            cursor: "copy",
          }}
          onClick={() => {
            navigator.clipboard.writeText(productData.location.completeAddress);
          }}
        >
          <Box sx={{ mr: 1, color: COLOR.neutral500 }}>
            <LocationIcon size={14} />
          </Box>
          <Typography textAlign="left" fontSize={14}>
            {productData.location.completeAddress}
          </Typography>
        </OpacityButton>
      </Box>
    </AppContainer>
  );
};

export default DProduct;
