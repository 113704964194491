import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";

export const ChooseImageContainer: SxProps<Theme> = {
  border: `1px dotted ${COLOR.neutral400}`,
  p: 2,
  borderRadius: 2,
  display: 'flex',
  gap: 2,
  justifyContent: 'center',
  alignItems: 'center',
};
export const ImageThumbContainer: SxProps<Theme> = {
  position: 'relative',
  backgroundColor: COLOR.primary50,
  borderRadius: 2,
  width: '90px',
  aspectRatio: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
};

export const ImageRounded: SxProps<Theme> = {
  borderRadius: 2,
  width: '100%',
  overflow:'hidden',
  height: '100%',
}
export const CloseBtn: SxProps<Theme> = {
  position: 'absolute',
  top: "-8px",
  right: "-8px",
  backgroundColor: COLOR.primary500,
  color: 'white',
  width: '24px',
  height: '24px',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  cursor:'pointer',
  outline:'none',
  border: 'none',
  padding: 0,
}