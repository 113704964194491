import { Typography, TypographyProps } from "@mui/material"
import { COLOR } from "../../../utils/color";


interface InputTitlePropsI {
  title?: string;
  required?: boolean;
  textProps?: TypographyProps;
  prefix?: React.ReactNode;
}

const InputTitle: React.FC<InputTitlePropsI> = (props) => {
  const { required = false } = props;
  if (props.title === undefined) return null;

  return (
    <Typography
      variant='body1'
      fontWeight={500}
      minHeight={props.title !== undefined ? 24 : undefined}
      {...props.textProps}
    >
      {props.title}{" "}
      {(required && props.title !== undefined) && (
        <Typography
          component="span"
          color={COLOR.danger500}
        >*</Typography>
      )}
      {props.prefix}
    </Typography>
  )
};

export default InputTitle;