import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";

export const ThumbnailButtonContainer: SxProps<Theme> = {
  outline: 'none',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: COLOR.primary50,
  boxShadow: '0px 1px 18px -2px rgba(0,0,0,0.86)',
  WebkitBoxShadow: '0px 1px 18px -2px rgba(0,0,0,0.86)',
  MozBoxShadow: '0px 1px 18px -2px rgba(0,0,0,0.86)',
  border: 'none',
  p: 2,
  borderRadius: 2,
  alignItems: 'center',
  gap: 2,
  cursor: 'pointer',
  transition: 'all 0.5s ease'
};

export const RowContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
}
export const ColContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
}
export const ThumbContentContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  flexGrow: 1,
  wordBreak: 'break-word',
};

export const CalloutBtn: SxProps<Theme> = {
  width: '100%', 
  display: 'flex', 
  justifyContent:'center', 
  alignItems:'center',
  gap: 1,
  backgroundColor: COLOR.primary50,
  boxShadow: 3,
  border: 'none',
  p: 2,
  borderRadius: 2,
  cursor: 'pointer',
  transition: 'all 0.5s ease',
}
export const ThumbImgContainer: SxProps<Theme> = {
  width: "60px",
  aspectRatio: 1,
  borderRadius: '12px',
  overflow: 'hidden',
  backgroundColor: 'transparent',
  flexShrink: 0,
};

export const PriceSx: SxProps<Theme> = {
  textAlign: 'left',
  fontSize: 18,
  fontWeight: 500,
}