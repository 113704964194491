import { THUMB_STYLE } from "../../utils/constant";
import "./ThumbnailButton.css";
import { COLOR } from "../../utils/color";
import { Box, SxProps, Typography } from "@mui/material";
import {
  CalloutBtn,
  ColContainer,
  PriceSx,
  RowContainer,
  ThumbContentContainer,
  ThumbImgContainer,
  ThumbnailButtonContainer,
} from "./ThumbnailButton.styles";
import { formatCurrency } from "../../utils/helper";
import { CurrencyCodeType } from "../../types/globalTypes";
import { Theme } from "@emotion/react";
import { FaArrowRight } from "react-icons/fa";
// import { DateValidationError, LocalizationProvider, PickerChangeHandlerContext, StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from "dayjs";
// import isEmpty from 'lodash.isempty';
import CalendarPicker from "../CalendarPIcker/CalendarPicker";

export type ThumbnailButtonData = {
  img: string;
  title: string;
  subtitle: string;
  buttonText: string;
  price?: number;
  discount?: number;
  currency?: CurrencyCodeType;
  selectedDate?: Dayjs;
};
interface ThumbnailButtonPropsI {
  type: THUMB_STYLE;
  primaryColor?: string;
  baseColor?: string;
  onClick?: () => void;
  onChangeSelectedDate?: (value: Dayjs | null) => void;
  data: ThumbnailButtonData;
  disabled?: boolean;
}

const ThumbnailPrice = (props: {
  price: number;
  discountPrice: number;
  currency?: CurrencyCodeType;
  color?: string;
}) => {
  const { color = "black" } = props;
  const isDiscount = props.discountPrice > 0;
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      justifyContent="start"
      alignItems="center"
      columnGap={1}
    >
      {isDiscount && (
        <Typography sx={PriceSx} variant="caption" color={color}>
          {props.price
            ? formatCurrency(props.discountPrice, props?.currency || "IDR")
            : "Free"}
        </Typography>
      )}
      <Typography
        sx={{
          ...PriceSx,
          textDecoration: isDiscount ? "line-through" : "normal",
          fontSize: isDiscount ? 12 : 18,
          opacity: isDiscount ? 0.5 : 1,
        }}
        variant="caption"
        color={color}
      >
        {props.price
          ? formatCurrency(props.price, props?.currency || "IDR")
          : "Free"}
      </Typography>
    </Box>
  );
};
const ThumbnailButton = (props: ThumbnailButtonPropsI) => {
  const {
    primaryColor = COLOR.primary500,
    baseColor = "black",
    disabled = false,
  } = props;

  const showPrice =
    props.data.discount !== undefined && !props.data.price !== undefined;
  switch (props.type) {
    case THUMB_STYLE.CALLOUT:
      return (
        <Box
          sx={
            {
              ...ThumbnailButtonContainer,
              ...ColContainer,
              backgroundColor: primaryColor,
              color: baseColor,
              cursor: "default",
            } as SxProps<Theme>
          }
        >
          <Box
            sx={{
              ...RowContainer,
              columnGap: 2,
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Box sx={ThumbImgContainer}>
              <img
                className="img-default"
                style={{ objectFit: "contain" }}
                src={props.data.img}
                alt={props.data.title}
              />
            </Box>
            <Box sx={ThumbContentContainer}>
              <Typography fontWeight={600} fontSize={18} textAlign="left">
                {props.data.title}
              </Typography>
              <Typography fontSize={14} sx={{ opacity: 0.7 }}>
                {props.data.subtitle}
              </Typography>
              {showPrice && (
                <ThumbnailPrice
                  color={props.baseColor}
                  price={props.data.price as number}
                  discountPrice={props.data.discount as number}
                  currency={props.data.currency}
                />
              )}
            </Box>
          </Box>
          <Box
            component="button"
            disabled={props.disabled}
            onClick={props.onClick}
            sx={{
              ...CalloutBtn,
              backgroundColor: props.baseColor,
              color: primaryColor,
            }}
          >
            <Typography fontWeight={600}>{props.data.buttonText}</Typography>
            <FaArrowRight />
          </Box>
        </Box>
      );
    case THUMB_STYLE.PREVIEW:
      return (
        <Box
          sx={
            {
              ...ThumbnailButtonContainer,
              ...ColContainer,
              backgroundColor: primaryColor,
              color: props.baseColor,
              cursor: "default",
            } as SxProps<Theme>
          }
        >
          <Box
            sx={{
              ...RowContainer,
              columnGap: 2,
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Box sx={ThumbImgContainer}>
              <img
                className="img-default"
                style={{ objectFit: "contain" }}
                src={props.data.img}
                alt={props.data.title}
              />
            </Box>
            <Box sx={ThumbContentContainer}>
              <Typography
                width="100%"
                fontWeight={600}
                fontSize={18}
                textAlign="left"
              >
                {props.data.title}
              </Typography>
              <Typography width="100%" fontSize={14} sx={{ opacity: 0.7 }}>
                {props.data.subtitle}
              </Typography>
              {showPrice && (
                <ThumbnailPrice
                  color={props.baseColor}
                  price={props.data.price as number}
                  discountPrice={props.data.discount as number}
                  currency={props.data.currency}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              ...ColContainer,
              width: "100%",
              alignItems: "center",
              overflowX: "scroll",
            }}
          >
            <CalendarPicker
              baseColor={props.baseColor}
              primaryColor={props.primaryColor}
              minDate={dayjs().subtract(1, "day")}
              value={props.data.selectedDate || dayjs()}
              onChange={(date) => {
                if (props.disabled) return;
                return props?.onChangeSelectedDate?.(date);
              }}
            />
          </Box>
          <Box width="100%">
            <Typography variant="caption">Hari yang dipilih</Typography>
            <Typography fontWeight={500} variant="h5">
              {(props.data.selectedDate || dayjs()).format("DD MMM YYYY")}
            </Typography>
          </Box>
          <Box
            component="button"
            disabled={props.disabled}
            onClick={props.onClick}
            sx={{
              ...CalloutBtn,
              backgroundColor: props.baseColor,
              color: primaryColor,
            }}
          >
            <Typography fontWeight={600}>{props.data.buttonText}</Typography>
            <FaArrowRight />
          </Box>
        </Box>
      );
    default:
    case THUMB_STYLE.BUTTON:
      return (
        <Box
          component="button"
          disabled={props.disabled}
          onClick={props.onClick}
          sx={{
            ...ThumbnailButtonContainer,
            color: props.baseColor,
            backgroundColor: primaryColor,
          }}
        >
          <Box sx={ThumbImgContainer}>
            <img
              className="img-default"
              style={{ objectFit: "contain" }}
              src={props.data.img}
              alt={props.data.title}
            />
          </Box>
          <Box
            sx={{
              ...ColContainer,
              alignItems: "start",
            }}
          >
            <Typography fontWeight={600} fontSize={18} textAlign="left">
              {props.data.buttonText}
            </Typography>
            {showPrice && (
              <ThumbnailPrice
                color={props.baseColor}
                price={props.data.price as number}
                discountPrice={props.data.discount as number}
                currency={props.data.currency}
              />
            )}
          </Box>
        </Box>
      );
  }
};

export default ThumbnailButton;
