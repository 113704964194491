import { Box } from '@mui/material';
import React from 'react';
import { COLOR } from '../../utils/color';

interface BoxStylePropsI {
  selected?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const BoxStyle: React.FC<BoxStylePropsI> = ({ selected, children, ...props }) => {
  return (
    <Box
      component="button"
      onClick={props.onClick}
      sx={{
        border: `1px solid ${COLOR.primary100}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '10px',
        width: '90px',
        aspectRatio: 1,
        borderRadius: 1,
        cursor: 'pointer',
        backgroundColor: 'white',
        color: 'black',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: COLOR.primary100,
        },
        '&:active': {
          backgroundColor: COLOR.primary100,
        },
        ...(selected && {
          backgroundColor: COLOR.primary100,
        })
      }}
    >
      {children}
    </Box>
  );
};

export default BoxStyle;
