import { Box, Typography } from "@mui/material";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import { ContainerStyle } from "./PageTemplate.styles";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { COLOR } from "../../../utils/color";
import {
  DEFAULT_SNACKBAR_PROPS,
  FONT_COLOR,
  ROUTE_NAME,
  RoutePath,
  STORAGE_KEY,
  SX_P_M_SIZE,
  TEMPLATE_HEX_COLOR,
  TEMPLATE_PAGE,
} from "../../../utils/constant";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import useLocalStorage from "../../../hooks/useLocalStorage";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { useStatusInfo } from "../../../context/StatusInfoContext";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import {
  resetBusinessData,
  setBusinessData,
} from "../../../redux/reducers/business";
import isEmpty from "lodash.isempty";
import { useSnackbar } from "notistack";
import { setUserData } from "../../../redux/reducers/user";
import {
  useCreateCompany,
  useCreateCompanyV2,
  useUploadBusinessImage,
} from "../../../query/mutations";
import { useState } from "react";
import { errorLogger } from "../../../utils/logger";
import { base64ToFile } from "../../../utils/helper";
import { PageTemplateType } from "../../../api/request.types";
import TemplateHome from "../../../components/TemplateHome/TemplateHome";
import CustomColorPicker from "../../../components/CustomColorPicker/CustomColorPicker";
import PhonePreview from "../../../components/PhonePreview/PhonePreview";
import { MAX_FILE_SIZE_IN_MB, compressImage } from "../../../utils/image";

interface PageTemplateI {}

export type BusinessTemplateData = {
  color: string;
  templateId: PageTemplateType;
};

export type TemplateData = {
  primary: string;
  secondary: string;
};
const PageTemplate: React.FC<PageTemplateI> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setStatusInfoContent } = useStatusInfo();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: businessData } = useSelector(
    (state: RootReducerState) => state.businessReducer
  );
  const { enqueueSnackbar } = useSnackbar();

  const [templateData, setTemplateData] = useLocalStorage<TemplateData>(
    STORAGE_KEY.TEMPLATE_DATA,
    { ...TEMPLATE_HEX_COLOR[0] }
  );

  const createCompanyMutation = useCreateCompanyV2();
  const uploadBusinessImageMutation = useUploadBusinessImage();

  const setupStatusInfoPage = () => {
    setStatusInfoContent({
      title: "Buka Usaha Berhasil!",
      description: "Selamat! Usaha Anda sudah bisa dilihat oleh semua orang.",
      primaryBtn: {
        text: "Tambah Produk Baru",
        onClick: () => {
          navigate(RoutePath[ROUTE_NAME.ADD_PRODUCT], { replace: true });
        },
      },
      secondaryBtn: {
        text: "Kembali ke Halaman Utama",
        onClick: () => {
          navigate(RoutePath[ROUTE_NAME.HOME], { replace: true });
        },
      },
    });
  };

  const onSuccessCreateCompany = (companyId: string) => {
    dispatch(
      setUserData({
        companyId,
      })
    );
    dispatch(resetBusinessData());
    setupStatusInfoPage();
    navigate(RoutePath[ROUTE_NAME.STATUS_INFO]);
  };
  const handleCreateCompany = async () => {
    try {
      setIsLoading(true);

      const response = await createCompanyMutation.mutateAsync({
        name: businessData.name,
        disabled: false,
        description: businessData.description,
        currency: "IDR",
        location: {
          mapLocation: businessData.location.search,
          completeAddress: businessData.locationDetail,
          useManualLocation: businessData.location.useManualLocation,
          longitude: businessData.location.lat,
          latitude: businessData.location.long,
          timezone: businessData.timezone,
        },
        companyDomainName: businessData.url,
        logoPath: "",
        template: TEMPLATE_PAGE[0].id,
        color: businessData.color,
        socialMedia: businessData.socialMedia,
      });
      const createdCompanyId = response.data.data;
      if (!isEmpty(businessData.image)) {
        const imageFile = base64ToFile(
          businessData.image as string,
          "profile-image"
        );

        if (imageFile.size > MAX_FILE_SIZE_IN_MB) {
          enqueueSnackbar({
            ...DEFAULT_SNACKBAR_PROPS,
            variant: "error",
            message: "Maximum File Size is 5MB",
          });
          return "";
        }

        const compressedImage = await compressImage(imageFile);
        await uploadBusinessImageMutation.mutateAsync({
          companyId: createdCompanyId,
          file: compressedImage,
        });
      }
      onSuccessCreateCompany(createdCompanyId);
    } catch (error) {
      errorLogger(error);
      enqueueSnackbar("Sedang terjadi kendala, mohon coba beberapa saat lagi", {
        ...DEFAULT_SNACKBAR_PROPS,
        variant: "error",
        preventDuplicate: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderPreviewContent = (
    <TemplateHome
      mode="preview"
      fontColor={FONT_COLOR}
      color={businessData?.color}
      logoSignedURL={businessData?.image}
      name={businessData?.name}
      description={businessData?.description}
      socialMedia={businessData?.socialMedia || {}}
      productDetails={[]}
      currency={"IDR"}
    />
  );

  return (
    <AppContainer
      bottomChildren={
        <Box
          sx={{
            borderTop: `1px solid ${COLOR.neutral200}`,
            minHeight: 80,
            width: "100%",
            px: 2,
            py: 1,
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          {/* <CustomButton
            fullWidth
            variant="outlined"
            disabled={isLoading}
            onClick={() => {
              navigate(RoutePath[ROUTE_NAME.PAGE_PREVIEW]);
            }}
          >
            <Typography variant="body1" fontWeight={500}>
              Preview
            </Typography>
          </CustomButton> */}
          <CustomButton
            fullWidth
            disabled={isLoading}
            onClick={() => handleCreateCompany()}
          >
            <Typography variant="body1" fontWeight={500}>
              Terapkan
            </Typography>
          </CustomButton>
        </Box>
      }
    >
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
          }}
          text={"Tampilan Usaha"}
        />

        <Box sx={{ mt: SX_P_M_SIZE }}>
          <Typography variant="h6" fontWeight={600} sx={{ mx: SX_P_M_SIZE }}>
            Pilih Tema Warna
          </Typography>
          <Box px={3} py={2} display="flex">
            <CustomColorPicker
              color={businessData.color || templateData.primary}
              onChangeColor={(color: string) => {
                dispatch(
                  setBusinessData({
                    ...businessData,
                    color,
                  })
                );
              }}
            />
          </Box>
          <Typography px={3} py={2} fontWeight={500}>
            Rekomendasi Warna
          </Typography>
          {/* <Typography
            variant="h6"
            fontWeight={600}
            sx={{ mx: SX_P_M_SIZE, mb: 2 }}
          >
            Pilih Tema Warna
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              overflowY: "scroll",
              pb: SX_P_M_SIZE,
              "&::-webkit-scrollbar": {
                width: 0,
                height: 2,
              },
            }}
          >
            {TEMPLATE_HEX_COLOR.map((colorPalete, index) => {
              const isSelected = colorPalete.primary === businessData.color;
              return (
                <OpacityButton
                  disableOpacity
                  disabled={isLoading}
                  onClick={() => {
                    dispatch(
                      setBusinessData({
                        ...businessData,
                        color: colorPalete.primary,
                      })
                    );
                    setTemplateData(colorPalete);
                  }}
                  key={index}
                  sx={{
                    width: "48px",
                    height: "48px",
                    backgroundColor: colorPalete.primary,
                    borderWidth: isSelected ? "4px" : 0,
                    borderStyle: "solid",
                    borderColor: colorPalete.secondary,
                    ml: index === 0 ? SX_P_M_SIZE : 1,
                    mr:
                      index === TEMPLATE_HEX_COLOR.length - 1 ? SX_P_M_SIZE : 1,
                    borderRadius: "50%",
                    flex: "0 0 auto",
                    "&:hover": {
                      opacity: 0.8,
                      cursor: "pointer",
                    },
                  }}
                />
              );
            })}
          </Box>
        </Box>

        {/* <Box
          sx={{
            px: SX_P_M_SIZE,
            mt: SX_P_M_SIZE,
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography variant="h6" fontWeight={600} mb={2}>
            Template Usaha
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                md: `repeat(3, 1fr)`,
                xs: `repeat(2, 1fr)`,
              },
              gap: 2,
              columnGap: 4,
              width: "100%",
              height: "100%",
            }}
          >
            {TEMPLATE_PAGE.map((item, index) => {
              const isSelected = item.id === businessData.templateId;
              return (
                <OpacityButton
                  disableOpacity
                  key={item.id}
                  onClick={() => {
                    dispatch(
                      setBusinessData({
                        templateId: item.id,
                      })
                    );
                  }}
                >
                  <Box
                    key={index}
                    sx={{
                      aspectRatio: "5/6",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `4px solid ${
                        isSelected ? COLOR.primary200 : COLOR.neutral200
                      }`,
                      borderRadius: 2,
                      overflow: "hidden",
                      pointerEvents: isLoading ? "none" : "initial",
                    }}
                  >
                    <img
                      src={`https://placehold.co/300x400?text=${item.id}`}
                      className="img-default"
                      alt={item.id}
                      style={{ objectFit: "cover" }}
                    />
                  </Box>
                  <Typography
                    variant="caption"
                    textAlign="left"
                    color={COLOR.neutral500}
                    textTransform="capitalize"
                  >
                    {item.id}
                  </Typography>
                </OpacityButton>
              );
            })}
          </Box>
        </Box> */}
        <Box display="flex" alignItems="center" flexDirection="column" py={5}>
          <Typography pb={3} fontWeight={600} variant="h5">
            Preview
          </Typography>
          <PhonePreview>{renderPreviewContent}</PhonePreview>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default PageTemplate;
