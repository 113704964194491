import OpacityButton, {
  OpacityButtonPropsI,
} from "../OpacityButton/OpacityButton";
import { COLOR } from "../../utils/color";
import { Box, Typography } from "@mui/material";
import StatusText from "../StatusText/StatusText";
import { TIMEZONE_SHORTLABEL } from "../../utils/constant";
import {
  CurrencyCodeType,
  PAYMENT_STATUS,
  TRANSACTION_STATUS,
} from "../../types/globalTypes";
import { APICompanyLocation } from "../../api/request.types";
import { formatCurrency, getStatusTextPropsByStatus } from "../../utils/helper";
import dayjs from "dayjs";
import React from "react";

interface TransactionCardPropsI {
  onClick: OpacityButtonPropsI["onClick"];
  id: string;
  createdAt: Date;
  timezone: APICompanyLocation["timezone"];
  name: string;
  qty: number;
  status: TRANSACTION_STATUS | PAYMENT_STATUS;
  amount: number;
  currency: CurrencyCodeType;
}
const TransactionCard: React.FC<TransactionCardPropsI> = (props) => {
  return (
    <OpacityButton
      sx={{
        border: `1px solid ${COLOR.neutral200}`,
        borderRadius: "12px",
        width: "100%",
        pb: 1,
      }}
      onClick={props.onClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          borderBottom: `1px solid ${COLOR.neutral200}`,
          p: 1,
        }}
      >
        <Typography fontWeight={600} fontSize="12px" color={COLOR.neutral500}>
          ID: {props.id}
        </Typography>
        <Typography fontWeight={400} fontSize="12px" color={COLOR.neutral500}>
          {dayjs(props.createdAt).format("DD MMM YYYY - HH.mm")}{" "}
          {TIMEZONE_SHORTLABEL[props.timezone as string] ||
            TIMEZONE_SHORTLABEL[0]}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        px={1}
        py="12px"
      >
        <Box
          textAlign="left"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <StatusText {...getStatusTextPropsByStatus(props.status)} />
          <Typography
            marginBottom={0.5}
            marginTop={0.5}
            fontWeight={400}
            fontSize="14px"
          >
            {props.name}
          </Typography>
          <Typography marginBottom={0.5} fontWeight={600} fontSize="16px">
            {formatCurrency(props.amount, props.currency)}
            <Typography
              ml="4px"
              variant="caption"
              fontWeight={400}
              fontSize="12px"
              color={COLOR.neutral500}
            >
              ({props.qty} item)
            </Typography>
          </Typography>
        </Box>
      </Box>
    </OpacityButton>
  );
};

export default TransactionCard;
