import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../utils/constant";
import { useResendVerificationEmail, useVerifyEmail } from "../query/mutations";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../utils/logger";
import SubMenuHeader from "../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import AppContainer from "../components/Layout/AppContainer/AppContainer";

const VerifyEmail: React.FC = () => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isResending, setIsResending] = useState(false);
  const [verificationAttempted, setVerificationAttempted] = useState(false);
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const verifyEmailMutation = useVerifyEmail();
  const resendVerificationEmailMutation = useResendVerificationEmail();

  const navigate = useNavigate();

  const handleVerifyEmail = useCallback(async () => {
    try {
      await verifyEmailMutation.mutateAsync({
        token: token || "",
        email: email || "",
      });
      setIsVerified(true);
    } catch (error) {
      setIsVerified(false);
      if (isAxiosError(error) && error.response?.status === 404) {
        const msg = `Email tidak valid`;

        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: msg,
        });
      } else if (isAxiosError(error) && error.response?.status === 400) {
        const msg = error.response?.data?.message || "";
        let shownMessage =
          "Terjadi kesalahan, mohon coba dalam beberapa saat lagi";
        if (msg === "Invalid Token") shownMessage = "Token tidak valid";
        if (msg === "Token has expired")
          shownMessage =
            "Token sudah expired. Silakan mengajukan permintaan ulang";
        if (msg === "User has been verified") shownMessage = "User sudah aktif";

        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: shownMessage,
        });
      } else {
        errorLogger(error);
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
        });
      }
    }
  }, [email, token, verifyEmailMutation]);

  useEffect(() => {
    if (email && token && !verificationAttempted) {
      handleVerifyEmail();
      setVerificationAttempted(true);
    }
  }, [email, token, verificationAttempted, handleVerifyEmail]);

  const handleResend = async () => {
    setIsResending(true);
    try {
      if (email) {
        await resendVerificationEmailMutation.mutateAsync({ email });
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "success",
          message: "Email Verifikasi telah dikirimkan kembali",
        });
      } else {
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
        });
      }
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        const msg = `Email tidak valid`;

        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: msg,
        });
      } else if (isAxiosError(error) && error.response?.status === 400) {
        const msg = error.response?.data?.message || "";
        let shownMessage =
          "Terjadi kesalahan, mohon coba dalam beberapa saat lagi";
        if (msg === "Existing Verification Link is Still Active")
          shownMessage = "Link Verifikasi Sebelumnya Masih Aktif";
        if (msg === "User has been verified") shownMessage = "User sudah aktif";

        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: shownMessage,
        });
      } else {
        errorLogger(error);
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
        });
      }
    }
    navigate(RoutePath[ROUTE_NAME.LOGIN]);

    setIsResending(false);
  };

  useEffect(() => {
    if (isVerified) {
      const redirectTimeout = setTimeout(() => {
        navigate(RoutePath[ROUTE_NAME.LOGIN]);
      }, 3000);
      return () => clearTimeout(redirectTimeout);
    }
  }, [isVerified, navigate]);

  if (isVerified) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        gap={2}
        textAlign="center"
      >
        <Typography variant="h6" color="success.main">
          Your email has been verified successfully!
        </Typography>
        <Typography variant="body1">
          Redirecting to login page in 3 seconds, or you can click the button
          below.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(RoutePath[ROUTE_NAME.LOGIN])}
        >
          Login Now
        </Button>
      </Box>
    );
  }

  return (
    <AppContainer sx={{ display: "flex", flexDirection: "column" }}>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(RoutePath[ROUTE_NAME.LOGIN], { replace: true });
          },
        }}
        text={"Verifikasi Email"}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        flexGrow={1}
      >
        {!isVerified ? (
          <>
            <Typography variant="h6" color="error">
              Email verification failed or not yet verified.
            </Typography>
            <Typography variant="body1" mb={2}>
              Please click the button below to resend the verification email.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleResend}
              disabled={isResending}
            >
              {isResending ? (
                <CircularProgress size={24} />
              ) : (
                "Resend Verification Email"
              )}
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1">
              Checking email verification status...
            </Typography>
            <CircularProgress />
          </>
        )}
      </Box>
    </AppContainer>
  );
};

export default VerifyEmail;
