import React, { useState } from "react";
import { IconButton, Grid, Typography, Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { COLOR } from "../../../utils/color";

const daysOfWeek = ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];

interface DatePickerPropsI {
  value: Dayjs | null;
  onChange: (newDate: Dayjs | null, isNavigate?: boolean) => void;
  baseColor?: string;
  primaryColor?: string;
  disabledDates?: Array<string>; // Array [YYYY-MM-DD]
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabledNext?: boolean;
  disabledPrev?: boolean;
  children?: React.ReactNode;
}
const DatePicker: React.FC<DatePickerPropsI> = ({
  value,
  onChange,
  baseColor = "black",
  primaryColor = COLOR.primary500,
  disabledDates = [],
  minDate,
  maxDate,
  disabledNext = false,
  disabledPrev = false,
  ...props
}) => {
  const today = dayjs();
  const [currentMonth, setCurrentMonth] = useState(dayjs(value || today));

  const handlePrevMonth = () => {
    if (!disabledPrev) {
      setCurrentMonth(currentMonth.subtract(1, "month"));
    }
  };

  const handleNextMonth = () => {
    if (!disabledNext) {
      setCurrentMonth(currentMonth.add(1, "month"));
    }
  };

  const startOfMonth = currentMonth.startOf("month").startOf("week");
  const endOfMonth = currentMonth.endOf("month").endOf("week");

  const calendarDates = [];
  let currentDay = startOfMonth;

  while (currentDay.isBefore(endOfMonth)) {
    calendarDates.push(currentDay);
    currentDay = currentDay.add(1, "day");
  }


  const handleDateClick = (date: Dayjs) => {
    onChange(date);
  };

  return (
    <Box
      sx={{ maxWidth: 300, textAlign: "center", transition: "all 0.5s ease" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <IconButton
          onClick={handlePrevMonth}
          disabled={disabledPrev}>
          <FaCaretLeft color={baseColor} />
        </IconButton>

        <Typography variant="h6" component="div">
          {currentMonth.format("MMMM YYYY")}
        </Typography>

        <IconButton
          onClick={handleNextMonth}
          disabled={disabledNext}>
          <FaCaretRight color={baseColor} />
        </IconButton>
      </Box>

      <Grid container spacing={1}>
        {daysOfWeek.map((day) => (
          <Grid item xs={1.71} key={day}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{ color: baseColor }}
            >
              {day}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={1} sx={{ mt: 1 }}>
        {calendarDates.map((date) => {
          const isToday = date.isSame(today, "day");
          const isSelected = value ? date.isSame(value, "day") : false;
          const isDisabledDate = disabledDates.some((dateStr) =>
            dayjs(dateStr, "YYYY-MM-DD").isSame(date, "day")
          );
          const isOutOfRange =
            (minDate && date.isBefore(minDate, "day")) ||
            (maxDate && date.isAfter(maxDate, "day")) || false;
          const isDisabled = isDisabledDate || isOutOfRange;

          return (
            <Grid item xs={1.71} key={date.toString()}>
              <Box
                onClick={() => {
                  if (!isDisabled) {
                    handleDateClick(date);
                  }
                }}
                sx={{
                  backgroundColor: isSelected
                    ? baseColor
                    : isDisabled
                      ? baseColor
                      : primaryColor,
                  color: isDisabled ? primaryColor : isSelected ? primaryColor : baseColor,
                  borderRadius: "50%",
                  width: 35,
                  height: 35,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                  opacity: isDisabled ? 0.5 : 1,
                  border: isToday ? `2px solid ${baseColor}` : "none",
                }}
              >
                {date.date()}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {props.children}
    </Box>
  )
};

export default DatePicker;