import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../../utils/color";

export const ContainerStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

export const FooterContainer: SxProps<Theme> = {
  borderTop: `1px solid ${COLOR.neutral200}`,
  minHeight: 80,
  width: "100%",
  px: 2,
  py: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: 'center',
  alignItems: 'center',
  gap: 2,
};


export const RowSx: SxProps<Theme> = {
  display: 'flex',
  flexDirecton: 'row',
}
export const FieldContainer: SxProps<Theme> = {
  px: 2,
  py: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export const PreviewContainer: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  maxWidth: '400px',
  flexDirection: 'column',
  alignItems: 'center',
  p: 2,
  borderRadius: 2,
}