import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  capitalize,
} from "@mui/material";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { COLOR } from "../../utils/color";
import {
  CurrencyCodeType,
  PAYMENT_STATUS,
  TRANSACTION_STATUS,
} from "../../types/globalTypes";
import SingleSelect from "../../components/Forms/SingleSelect/SingleSelect";
import {
  useGetProductReservationCompactList,
  useGetTransactionByQuery,
} from "../../query/queries";
import TransactionCard from "../../components/TransactionCard/TransactionCard";
import { RootReducerState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { useQuery } from "../../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../../utils/constant";
import { getStatusTextPropsByStatus } from "../../utils/helper";
import { APIGetProductCompact } from "../../api/request.types";
import { MdOutlineFileDownload } from "react-icons/md";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import { useDownloadCSVTransaction } from "../../query/mutations";
import { enqueueSnackbar } from "notistack";

type StatusType = PAYMENT_STATUS | TRANSACTION_STATUS | "all";

const Transaction = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const today = dayjs();

  const defaultStartDate = today.subtract(7, "day");
  const defaultEndDate = today;

  const queryStartDate = query.get("startDate");
  const queryEndDate = query.get("endDate");

  const queryStatus = query.get("status") || "all";
  const queryProductID = query.get("productIDs") || "all";

  const startDate = queryStartDate ? dayjs(queryStartDate) : defaultStartDate;
  const endDate = queryEndDate ? dayjs(queryEndDate) : defaultEndDate;

  const [selectedStatus, setSelectedStatus] = useState<StatusType>(
    queryStatus as StatusType
  );
  const [selectedProductId, setSelectedProductId] =
    useState<string>(queryProductID);

  const [timeRange, setTimeRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({
    startDate,
    endDate,
  });

  const [openPicker, setOpenPicker] = useState<"start" | "end" | null>(null);

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const getTransactionListQueries = useGetTransactionByQuery({
    companyID: userReducer.data.companyId,
    endDate: timeRange.endDate.format("YYYY-MM-DD"),
    startDate: timeRange.startDate.format("YYYY-MM-DD"),
    status: selectedStatus === "all" ? undefined : selectedStatus,
    productIDs: selectedProductId === "all" ? undefined : selectedProductId,
  });
  const downloadTransactionCSVMutation = useDownloadCSVTransaction();

  const productCompactListQuery = useGetProductReservationCompactList(
    userReducer.data.companyId || ""
  );

  const productList: APIGetProductCompact[] = useMemo(
    () => productCompactListQuery.data?.data.data || [],
    [productCompactListQuery.data?.data.data]
  );

  const productListOptions = useMemo(() => {
    return [
      { label: "Semua", value: "all" },
      ...productList.map((productCompact) => ({
        label: productCompact.productName,
        value: productCompact.productID,
      })),
    ];
  }, [productList]);

  const handleDateChange = (newDate: Dayjs | null, type: "start" | "end") => {
    if (!newDate) return;

    setTimeRange((prev) => ({
      ...prev,
      [type === "start" ? "startDate" : "endDate"]: newDate,
    }));
    setOpenPicker(null);
  };

  const statusListOption = [
    "all",
    ...Object.values(PAYMENT_STATUS),
    ...Object.values(TRANSACTION_STATUS),
  ].map((status) => {
    const props = getStatusTextPropsByStatus(
      status as PAYMENT_STATUS | TRANSACTION_STATUS
    );
    return {
      label: status === "all" ? "Semua" : (props.children as string),
      value: status,
    };
  });

  const transactionList = useMemo(() => {
    return getTransactionListQueries.data?.data?.data || [];
  }, [getTransactionListQueries.data?.data?.data]);

  const updateURLParams = useCallback(
    (
      newStartDate: Dayjs,
      newEndDate: Dayjs,
      status: StatusType,
      productId: string
    ) => {
      const params = new URLSearchParams();
      params.set("startDate", newStartDate.format("YYYY-MM-DD"));
      params.set("endDate", newEndDate.format("YYYY-MM-DD"));
      if (status !== "all") {
        params.set("status", status);
      }
      if (productId !== "all") {
        params.set("productIDs", productId);
      }
      navigate(`/admin/transaction?${params.toString()}`, { replace: true });
    },
    [navigate]
  );

  useEffect(() => {
    updateURLParams(
      timeRange.startDate,
      timeRange.endDate,
      selectedStatus,
      selectedProductId
    );
  }, [timeRange, selectedStatus, selectedProductId, updateURLParams]);

  return (
    <MobileContainer>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
        }}
        text={"Transaksi"}
      />
      <Box p={2} display="flex" flexDirection="column" gap={1}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          gap={1}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            onClick={() => setOpenPicker("start")}
          >
            <Typography variant="caption" fontWeight={600}>
              Transaksi Mulai
            </Typography>
            <Box
              width="100%"
              borderRadius={3}
              overflow="hidden"
              border={`1px solid ${COLOR.neutral300}`}
              px={1}
              py="12px"
            >
              <Typography variant="caption" fontSize={16}>
                {timeRange.startDate.format("DD MMM YYYY")}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            onClick={() => setOpenPicker("end")}
          >
            <Typography variant="caption" fontWeight={600}>
              Transaksi Akhir
            </Typography>
            <Box
              width="100%"
              borderRadius={3}
              overflow="hidden"
              border={`1px solid ${COLOR.neutral300}`}
              px={1}
              py="12px"
            >
              <Typography variant="caption" fontSize={16}>
                {timeRange.endDate.format("DD MMM YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          gap={1}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="caption" fontWeight={600}>
              Pilih Produk
            </Typography>
            <SingleSelect
              optionProps={{
                value: selectedProductId,
                onChange: (newValue?: string) => {
                  setSelectedProductId(newValue || "all");
                },
                options: productListOptions,
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="caption" fontWeight={600}>
              Status Transaksi
            </Typography>
            <SingleSelect
              optionProps={{
                value: selectedStatus,
                onChange: (newValue?: string) => {
                  setSelectedStatus((newValue as StatusType) || "all");
                },
                options: statusListOption,
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          justifyContent="end"
          gap={1}
          mt={1}
          pr={1}
        >
          <OpacityButton
            disabled={downloadTransactionCSVMutation.isLoading}
            onClick={async () => {
              try {
                const response =
                  await downloadTransactionCSVMutation.mutateAsync({
                    query: {
                      companyID: userReducer.data.companyId,
                      endDate: timeRange.endDate.format("YYYY-MM-DD"),
                      startDate: timeRange.startDate.format("YYYY-MM-DD"),
                      status:
                        selectedStatus === "all" ? undefined : selectedStatus,
                      productIDs:
                        selectedProductId === "all"
                          ? undefined
                          : selectedProductId,
                    },
                  });
                if (response.data.data) {
                  window.open(response.data.data, "_blank");
                } else {
                  enqueueSnackbar({
                    ...DEFAULT_SNACKBAR_PROPS,
                    variant: "error",
                    message: "Error Download CSV",
                  });
                }
              } catch (err) {
                enqueueSnackbar({
                  ...DEFAULT_SNACKBAR_PROPS,
                  variant: "error",
                  message: "Error Download CSV",
                });
              }
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <MdOutlineFileDownload size={30} color={COLOR.neutral900} />
              <Typography
                fontSize={14}
                fontWeight={600}
                color={COLOR.neutral900}
              >
                Download CSV
              </Typography>
              {downloadTransactionCSVMutation.isLoading && (
                <CircularProgress
                  style={{ marginLeft: "10px" }}
                  size={20}
                  color="inherit"
                />
              )}
            </Box>
          </OpacityButton>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap={2} mt={2}>
          {getTransactionListQueries.isLoading &&
            new Array(10).fill("").map((_, idx) => (
              <Box
                key={`skeleton-${idx}`}
                sx={{
                  border: `1px solid ${COLOR.neutral200}`,
                  borderRadius: "12px",
                  width: "100%",
                  pb: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    borderBottom: `1px solid ${COLOR.neutral200}`,
                    p: 1,
                    gap: 2,
                  }}
                >
                  <Skeleton width="30%" height="20px" />
                  <Skeleton width="40%" height="20px" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  px={1}
                  gap={2}
                >
                  <Box
                    flex={1}
                    textAlign="left"
                    display="flex"
                    flexDirection="column"
                  >
                    <Skeleton width="130px" height="100%" />
                    <Skeleton width="100px" height="100%" />
                    <Skeleton width="180px" height="100%" />
                  </Box>
                </Box>
              </Box>
            ))}

          {!getTransactionListQueries.isLoading &&
            transactionList.length > 0 &&
            transactionList.map((transactionDetail) => {
              return (
                <TransactionCard
                  key={transactionDetail.transactionID}
                  onClick={() => {
                    navigate(
                      `${RoutePath[ROUTE_NAME.TRANSACTION]}/${
                        transactionDetail.transactionID
                      }`
                    );
                  }}
                  id={transactionDetail.transactionID}
                  createdAt={new Date(transactionDetail.createdAt)}
                  timezone={transactionDetail.timezone}
                  name={transactionDetail.productName}
                  qty={transactionDetail.totalItems}
                  status={transactionDetail.paymentStatus as PAYMENT_STATUS}
                  amount={transactionDetail.total}
                  currency={transactionDetail.currency as CurrencyCodeType}
                />
              );
            })}

          {!getTransactionListQueries.isLoading &&
            transactionList.length === 0 && (
              <Typography>Tidak ada Transaksi untuk saat ini.</Typography>
            )}
        </Box>

        <MobileDatePicker
          sx={{ display: "none" }}
          open={openPicker === "start"}
          onClose={() => setOpenPicker(null)}
          value={timeRange.startDate}
          onChange={(newDate) => handleDateChange(newDate, "start")}
          disableFuture
        />
        <MobileDatePicker
          sx={{ display: "none" }}
          open={openPicker === "end"}
          onClose={() => setOpenPicker(null)}
          value={timeRange.endDate}
          onChange={(newDate) => handleDateChange(newDate, "end")}
          disableFuture
        />
      </Box>
    </MobileContainer>
  );
};

export default Transaction;
