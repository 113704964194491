import React, { RefObject } from 'react';
import { Box, Typography } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import CustomButton from '../CustomButton/CustomButton';
import HiddenInput from '../HiddenInput/HiddenInput';
import { ChooseImageContainer, CloseBtn, ImageRounded, ImageThumbContainer } from './SingleImageUploader.styles';
import { COLOR } from '../../utils/color';

interface SingleImageUploaderProps {
  img: string;
  fileInputRef: RefObject<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickRemove: () => void;
  title?: string;
  subtitle?: string;
}

const SingleImageUploader: React.FC<SingleImageUploaderProps> = ({
  img,
  fileInputRef,
  onChange,
  onClickRemove,
  ...props
}) => {
  return (
    <Box sx={ChooseImageContainer}>
      <Box sx={ImageThumbContainer}>
        <Box component="button" sx={CloseBtn} onClick={onClickRemove}>
          <IoMdClose />
        </Box>
        <Box sx={ImageRounded}>
          <img
            className="img-default"
            style={{ objectFit: 'cover' }}
            src={img}
            alt="thumb-img"
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" flex={1} width="fit-content" gap={1} alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" textAlign="center">
          <Typography color={COLOR.neutral500}>{props.title || "Thumbnail"}</Typography>
          <Typography variant="caption" color={COLOR.neutral400}>{props.subtitle || "400x400"}</Typography>
        </Box>
        <CustomButton
          variant="outlined"
          sx={{ py: 1, width: 'fit-content' }}
          onClick={() => fileInputRef?.current?.click?.()}>
          Pilih Gambar
        </CustomButton>
        <HiddenInput
          ref={fileInputRef}
          onChange={onChange}
          hideMethod="none"
        />
      </Box>
    </Box>
  );
};

export default SingleImageUploader;
