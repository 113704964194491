import { Box, Typography } from '@mui/material';
import React from 'react';
import { COLOR } from '../../utils/color';

interface CircledNumberPropsI {
  num: number;
  text: string;
}
const NumberedTitle = (props: CircledNumberPropsI) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection:'row',
      alignItems:'center',
      gap: 2,
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: COLOR.primary200,
        width: '30px',
        height: '30px',
        textAlign: 'center',
      }}>
        <Typography fontWeight={600}>
          {props.num}
        </Typography>
      </Box>
      <Typography fontWeight={500} >
        {props.text}
      </Typography>
    </Box>
  )
}

export default NumberedTitle;