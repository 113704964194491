import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  PRODUCT_TYPE,
  ROUTE_NAME,
  RoutePath,
  TAB_ENUM,
} from "../../../utils/constant";
import SubMenuHeader from "../../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft, FaBriefcase, FaCalendar, FaSquare } from "react-icons/fa";
import { ContainerStyle } from "./Product.styles";
import ScheduleProduct from "./ScheduleProduct";
import LinkProduct from "./LinkProduct";

const TABS = {
  [TAB_ENUM.THUMB]: {
    id: TAB_ENUM.THUMB,
    title: "Thumbnail",
    icon: <FaSquare />,
  },
  [TAB_ENUM.CHECKOUT]: {
    id: TAB_ENUM.CHECKOUT,
    title: "Checkout",
    icon: <FaBriefcase />,
  },
  [TAB_ENUM.AVAILABILITY]: {
    id: TAB_ENUM.AVAILABILITY,
    title: "Penjadwalan",
    icon: <FaCalendar />,
  },
};

export const NEW_PRODUCT_NAV = {
  RESERVATION: `${RoutePath[ROUTE_NAME.ADD_PRODUCT]}/new?type=${PRODUCT_TYPE.RESERVATION}`,
  LINK: `${RoutePath[ROUTE_NAME.ADD_PRODUCT]}/new?type=${PRODUCT_TYPE.LINK}`,
}
const Product: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { productId } = useParams<{ productId: string }>();

  const productType = searchParams.get("type") as PRODUCT_TYPE;
  const tabFromURL = (searchParams.get("tab") as TAB_ENUM) || TAB_ENUM.THUMB;

  const [tabValue, setTabValue] = useState<TAB_ENUM>(tabFromURL);

  const handleTabChange = (_: React.SyntheticEvent, newValue: TAB_ENUM) => {
    setTabValue(newValue);
    setSearchParams({ tab: newValue, type: productType });
  };

  useEffect(() => {
    setTabValue(tabFromURL);
  }, [tabFromURL]);

  useEffect(() => {
    if (!productId || !Object.values(PRODUCT_TYPE).includes(productType)) {
      navigate(RoutePath[ROUTE_NAME.BUSINESS], { replace: true });
    }
  }, [productType, navigate, productId]);

  useEffect(() => {
    setSearchParams({
      tab: tabValue,
      type: productType,
    });
  }, [productType, setSearchParams, tabFromURL, tabValue]);

  return (
    <AppContainer>
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => {
              navigate(RoutePath[ROUTE_NAME.BUSINESS], {
                replace: true,
              });
            },
          }}
          text={productId === "new" ? "Tambah Produk" : "Ubah Produk"}
        />
        {productType === PRODUCT_TYPE.RESERVATION && (
          <ScheduleProduct
            productType={productType}
            productId={productId || "new"}
            tabs={[
              TABS[TAB_ENUM.THUMB],
              TABS[TAB_ENUM.CHECKOUT],
              TABS[TAB_ENUM.AVAILABILITY],
            ]}
            tabValue={tabValue}
            onChangeTab={handleTabChange}
          />
        )}
        {productType === PRODUCT_TYPE.LINK && (
          <LinkProduct
            productType={productType}
            productId={productId || "new"}
          />
        )}
      </Box>
    </AppContainer>
  );
};

export default Product;
