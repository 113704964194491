import "@mdxeditor/editor/style.css";
import { MDXEditor, MDXEditorProps, MDXEditorMethods } from "@mdxeditor/editor";
import "./CustomMDXEditor.css";
import { useRef } from "react";
import {} from "./CustomTextEditor";
import { ALL_PLUGINS } from "./MDXConfig";

interface CustomMDXEditorPropsI extends MDXEditorProps {
  companyId: string;
}
const CustomMDXEditor: React.FC<CustomMDXEditorPropsI> = (props) => {
  const ref = useRef<MDXEditorMethods | null>(null);
  return (
    <div
      style={{ boxSizing: "border-box" }}
      onClick={() => {
        ref.current?.focus();
      }}
    >
      {/* <MDXEditor
     {...props}
     plugins={[
       toolbarPlugin({
         toolbarContents: () => (
           <>
             <UndoRedo />
             <BoldItalicUnderlineToggles />
             <CreateLink />
             <ListsToggle />
             <InsertTable />
             <InsertImage />
           </>
         )
       }),
       headingsPlugin(),
       listsPlugin(),
       quotePlugin(),
       thematicBreakPlugin(),
       linkPlugin(),
       linkDialogPlugin(),
       imagePlugin({ imageUploadHandler }),
       tablePlugin(),
       ...(props?.plugins || []),
     ]}
     ref={ref}
   /> */}
      <MDXEditor
        ref={ref}
        markdown={props.markdown}
        onChange={(md) => {
          props?.onChange?.(md);
        }}
        plugins={ALL_PLUGINS(props.companyId)}
      />
    </div>
  );
};

export default CustomMDXEditor;
