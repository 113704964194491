import { Theme } from '@emotion/react';
import { Box, SxProps } from '@mui/material';
import React from 'react';

const containerStyle: SxProps<Theme> = {
  width: '370px',
  aspectRatio: '9/16',
  borderRadius: '24px',
  border: `8px solid black`,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  boxShadow: '2',
};

const innerContainerStyle: SxProps<Theme> = {
  display:'flex',
  flexDirection:'column',
  flex: 1,
  overflowY: 'scroll',
  width: '100%',
  gap: 2,
  alignItems:'center',
};

interface PhonePreviewPropsI {
  children?: React.ReactNode;
  primaryColor?: string;
}

const PhonePreview: React.FC<PhonePreviewPropsI> = (props) => {
  return (
    <Box sx={containerStyle}>
      <Box sx={{ ...innerContainerStyle, backgroundColor: props.primaryColor }}>
        {props.children}
      </Box>
    </Box>
  );
};

export default PhonePreview;
