import { To, useNavigate, useParams } from "react-router-dom";

const useNavigateDynamicPage = () => {
  const _navigate = useNavigate();
  const params = useParams<{ companyDomainName: string }>();

  const navigate = (
    to: string | number | { pathname: string; search?: string; state?: any },
    options?: any
  ) => {
    // Determine the path to navigate to
    const replaceTo =
      typeof to === "string"
        ? to.replace(":companyDomainName", params.companyDomainName || "")
        : typeof to === "number"
        ? to
        : {
            ...to,
            pathname: to.pathname.replace(
              ":companyDomainName",
              params.companyDomainName || ""
            ),
          };

    // Call the original navigate function
    _navigate(replaceTo as To, options);
  };

  return navigate;
};

export default useNavigateDynamicPage;
