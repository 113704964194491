import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";

export const FooterContainer: SxProps<Theme> = {
  borderTop: `1px solid ${COLOR.neutral200}`,
  minHeight: 80,
  width: "100%",
  px: 2,
  py: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: 'center',
  alignItems: 'center',
  gap: 2,
};
