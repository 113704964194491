import { Box, Typography } from "@mui/material";
import { FooterContainer } from "./ProductFooter.styles";
import CustomButton from "../CustomButton/CustomButton";
import { FaTrash } from "react-icons/fa";
import { COLOR } from "../../utils/color";

interface ProductFooterPropsI {
  onClickTrash: () => void;
  onClickDraft: () => void;
  onClickPublish: () => void;
  trashDisabled?: boolean;
  draftDisabled?: boolean;
  publishDisabled?: boolean;
  productId?: string;
  productStatus?: "draft" | "active";
}

const ProductFooter = (props: ProductFooterPropsI) => {
  const isEdit = props.productId !== "new";
  const isDraft = props.productStatus === "draft";
  return (
    <Box sx={FooterContainer}>
      {isEdit && (
        <CustomButton
          disabled={props.trashDisabled}
          onClick={props.onClickTrash}
          variant="icon"
          sx={{
            backgroundColor: COLOR.danger50,
            color: COLOR.danger500,
          }}
        >
          <FaTrash size={20} />
        </CustomButton>
      )}
      <CustomButton
        disabled={props.draftDisabled}
        sx={{ width: "fit-content", px: 5 }}
        onClick={props.onClickDraft}
        variant="outlined"
      >
        <Typography
          variant="body1"
          fontWeight={500}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{ textWrap: "nowrap" }}
        >
          Arsipkan
        </Typography>
      </CustomButton>
      <CustomButton
        disabled={props.publishDisabled}
        onClick={props.onClickPublish}
      >
        <Typography variant="body1" fontWeight={500}>
          {isEdit && !isDraft ? "Ubah" : "Terbitkan"}
        </Typography>
      </CustomButton>
    </Box>
  );
};

export default ProductFooter;
