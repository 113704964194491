import { useEffect, useRef, useState } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { COLOR } from "../../utils/color";
import OpacityButton from "../OpacityButton/OpacityButton";
import { MobileDatePicker } from "@mui/x-date-pickers";
import {
  DateContainer,
  DatePickerContainer,
  MonthPickerContainer,
} from "./CustomDatePicker.styles";
import { generateDaysInRange } from "../../utils/date";
import { Theme } from "@emotion/react";

interface CustomDatePickerProps {
  value: Dayjs;
  onChange: (date: Dayjs) => void;
  disabled?: boolean;
  disabledNext?: boolean;
  disabledPrev?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  baseColor?: string;
  primaryColor?: string;
  secondaryColor?: string;
}

const CustomDatePicker = ({
  value,
  onChange,
  disabled = false,
  disabledNext = false,
  disabledPrev = false,
  minDate = dayjs(),
  maxDate = dayjs().add(1, "year"),
  baseColor = "black",
  primaryColor = COLOR.primary500,
  secondaryColor = "white",
}: CustomDatePickerProps) => {
  const today = dayjs();

  const [open, setOpen] = useState<boolean>(false);
  const [previewDate, setPreviewDate] = useState<Dayjs>(value);

  const firstDayOfMonth = previewDate.startOf("month");
  const lastDayOfMonth = previewDate.endOf("month");
  const startOfCalendar = firstDayOfMonth.startOf("week");
  const endOfCalendar = lastDayOfMonth.endOf("week");

  const days = generateDaysInRange(startOfCalendar, endOfCalendar);
  const isDayDisabled = (day: Dayjs) => {
    return day.isBefore(minDate, "day") || day.isAfter(maxDate, "day");
  };
  const canGoPrev = previewDate.isAfter(minDate, "month");
  const canGoNext = previewDate.isBefore(maxDate, "month");

  const daysContainerRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (date: Dayjs | null) => {
    if (date && !disabled) {
      onChange(date);
      setPreviewDate(date);
    }
  };

  useEffect(() => {
    const scrollToSelectedDate = () => {
      if (daysContainerRef.current) {
        const selectedDayElement = daysContainerRef.current.querySelector(
          `[data-day="${value.format("YYYY-MM-DD")}"]`
        );
        if (selectedDayElement) {
          selectedDayElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }
    };

    scrollToSelectedDate();
    window.addEventListener("resize", scrollToSelectedDate);
    return () => {
      window.removeEventListener("resize", scrollToSelectedDate);
    };
  }, [value]);

  const todayDateSx: SxProps<Theme> = {
    color: primaryColor,
    border: `1px solid ${primaryColor}`,
    backgroundColor: secondaryColor,
    opacity: 1,
  };
  const selectedDateSx: SxProps<Theme> = {
    color: secondaryColor,
    backgroundColor: primaryColor,
  };
  const disabledDateSx: SxProps<Theme> = {
    backgroundColor: secondaryColor,
    border: `1px solid ${secondaryColor}`,
    cursor: "not-allowed",
    opacity: 0.5,
  };
  return (
    <Box>
      <Box sx={MonthPickerContainer}>
        <OpacityButton
          sx={{ display: "flex", flexGrow: 1, py: 2, pl: 2 }}
          onClick={() => !disabled && setOpen(true)}
          disabled={disabled}
        >
          <Typography color={baseColor}>
            {value.format("MMMM, YYYY")}
          </Typography>
        </OpacityButton>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <OpacityButton
            sx={{ px: 2 }}
            onClick={() =>
              canGoPrev && setPreviewDate((prev) => prev.subtract(1, "month"))
            }
            disabled={!canGoPrev || disabledPrev || disabled}
          >
            <FiChevronLeft color={baseColor} />
          </OpacityButton>
          <OpacityButton
            sx={{ px: 2 }}
            onClick={() =>
              canGoNext && setPreviewDate((prev) => prev.add(1, "month"))
            }
            disabled={!canGoNext || disabledNext || disabled}
          >
            <FiChevronRight color={baseColor} />
          </OpacityButton>
        </Box>
      </Box>

      <MobileDatePicker
        sx={{ display: "none" }}
        open={open}
        onClose={() => setOpen(false)}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleDateChange}
        disabled={disabled}
      />

      <Box sx={DatePickerContainer} ref={daysContainerRef}>
        {days.map((day) => {
          const isSelectedDay = day.isSame(value, "day");
          const isDisabled = isDayDisabled(day);
          const isToday = day.isSame(today, "day");

          const dateContainerSx: SxProps<Theme> = {
            ...DateContainer,
            ...(isToday ? todayDateSx : {}),
            ...(isDisabled ? disabledDateSx : {}),
            ...(isSelectedDay ? selectedDateSx : {}),
          } as SxProps<Theme>;

          return (
            <Box
              key={day.format("YYYY-MM-DD")}
              data-day={day.format("YYYY-MM-DD")}
              onClick={() => !isDisabled && handleDateChange(day)}
              sx={dateContainerSx}
            >
              <Typography textAlign="center" fontWeight={400} variant="caption">
                {day.format("ddd")}
              </Typography>
              <Typography textAlign="center" fontWeight={700}>
                {day.format("DD")}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default CustomDatePicker;
