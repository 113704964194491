import { Box, Dialog, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import DatePicker from '../DatePicker/DatePicker';
import { COLOR } from '../../../utils/color';
import { FaCalendar } from 'react-icons/fa';

interface DateRangePickerPropsI {
  startDate: Dayjs;
  endDate: Dayjs;
  disabled?: boolean;
  onChangeStartDate?: (date: Dayjs) => void;
  onChangeEndDate?: (date: Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  baseColor?: string;
  primaryColor?: string;
  disabledDates?: Array<string>;
}

const DateRangePicker = ({
  startDate,
  endDate,
  disabled = false,
  onChangeStartDate,
  onChangeEndDate,
  minDate,
  maxDate,
  baseColor = "black",
  primaryColor = "white",
  disabledDates,
}: DateRangePickerPropsI) => {
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const [openEndPicker, setOpenEndPicker] = useState(false);

  const handleOpenStartPicker = () => setOpenStartPicker(true);
  const handleCloseStartPicker = () => setOpenStartPicker(false);

  const handleOpenEndPicker = () => setOpenEndPicker(true);
  const handleCloseEndPicker = () => setOpenEndPicker(false);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box
        p={1}
        border={`1px solid ${COLOR.neutral200}`}
        borderRadius={2} flex={1}
        component="button"
        sx={{ backgroundColor: 'inherit' }}
        onClick={handleOpenStartPicker} disabled={disabled}
      >
        <Typography
          fontWeight={500}
          fontSize={12}
          color={COLOR.neutral500}
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          gap={2}
        >
          Start Date <FaCalendar />
        </Typography>
        <Typography fontWeight={700} fontSize={16} textAlign="left">{startDate ? startDate.format('DD MMM YYYY') : 'Select Start Date'}</Typography>
      </Box>
      <Box
        p={1}
        border={`1px solid ${COLOR.neutral200}`}
        borderRadius={2} flex={1}
        component="button"
        sx={{ backgroundColor: 'inherit' }}
        onClick={handleOpenEndPicker} disabled={disabled}
      >
        <Typography
          fontWeight={500}
          fontSize={12}
          color={COLOR.neutral500}
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          gap={2}
        >
          End Date <FaCalendar />
        </Typography>
        <Typography fontWeight={700} fontSize={16} textAlign="left">{endDate ? endDate.format('DD MMM YYYY') : 'Select End Date'}</Typography>
      </Box>

      <Dialog open={openStartPicker} onClose={handleCloseStartPicker}>
        <Box sx={{ p: 2 }}>
          <DatePicker
            value={startDate}
            onChange={(newDate) => {
              if (newDate && onChangeStartDate) {
                onChangeStartDate(newDate);
              }
              handleCloseStartPicker();
            }}
            minDate={minDate}
            maxDate={maxDate}
            baseColor={baseColor}
            primaryColor={primaryColor}
            disabledDates={disabledDates}
          />
        </Box>

      </Dialog>

      <Dialog open={openEndPicker} onClose={handleCloseEndPicker}>
        <Box sx={{ p: 2 }}>
          <DatePicker
            value={endDate}
            onChange={(newDate) => {
              if (newDate && onChangeEndDate) {
                onChangeEndDate(newDate);
              }
              handleCloseEndPicker();
            }}
            minDate={startDate}
            maxDate={maxDate}
            baseColor={baseColor}
            primaryColor={primaryColor}
            disabledDates={disabledDates}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default DateRangePicker;
