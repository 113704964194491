import isEmpty from 'lodash.isempty';
import { COOKIE, USER_ROLE } from "../utils/constant";
import { useDispatch } from "react-redux";
import { clearAll } from "../redux/actions";
import { useEffect, useState } from "react";
import Cookies from 'js-cookie';

const useTokenCheck = () => {
  const userToken = Cookies.get(COOKIE.TOKEN);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!isEmpty(userToken));
  const dispatch = useDispatch();
  // const userReducer = useSelector((state: RootReducerState) => state.userReducer);
  useEffect(() => {
    const isAuthenticated: boolean = !isEmpty(userToken);
    setIsAuthenticated(isAuthenticated);
    if (!isAuthenticated) {
      dispatch(clearAll());
    }
  }, [dispatch, userToken]);
  return [isAuthenticated, USER_ROLE.ADMIN, userToken];
};

export default useTokenCheck