import { Box, Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton/CustomButton";
import {
  COOKIE,
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import MailIcon from "../../../assets/svg/MailIcon";
import LockIcon from "../../../assets/svg/LockIcon";
import EyeIcon from "../../../assets/svg/EyeOpenIcon";
import { useCallback, useMemo, useState } from "react";
import EyeClosedIcon from "../../../assets/svg/EyeClosedIcon";
import { COLOR } from "../../../utils/color";
import {
  useGetCompanies,
  useLogin,
  useLoginGoogle,
} from "../../../query/mutations";
import { LoginPayload, UserCompaniesType } from "../../../api/request.types";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import { errorLogger } from "../../../utils/logger";
import { loginSchema } from "../../../joiSchema/auth";
import Joi, { ValidationErrorItem } from "joi";
import BusinessDialog from "../../../components/BusinessListDialog/Dialog";
import { RootReducerState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../redux/reducers/user";
import { enqueueSnackbar } from "notistack";
import NeemaLogoPNG from "../../../assets/images/neema-logo.png";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";

const Login = () => {
  const [isLoadingGoogle, setIsLoadingGoogle] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [userToken, setUserToken] = useState<string | undefined>();
  const [loginForm, setLoginForm] = useState<LoginPayload>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<
    Record<keyof LoginPayload, string | undefined>
  >({
    email: undefined,
    password: undefined,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: { companyId },
  } = useSelector((state: RootReducerState) => state.userReducer);

  const [isOpenBusinessDialog, setIsOpenBusinessDialog] =
    useState<boolean>(false);
  const loginMutation = useLogin();
  const loginGoogleMutation = useLoginGoogle();
  const getCompaniesMutation = useGetCompanies();
  const isLoadingCompanies = getCompaniesMutation.isLoading;
  const businessListData: UserCompaniesType[] = useMemo(() => {
    return getCompaniesMutation.data?.data?.data || [];
  }, [getCompaniesMutation.data?.data?.data]);

  const handleOnSuccessLogin = async (token: string) => {
    setUserToken(token);
    Cookies.set(COOKIE.TOKEN, token, { expires: 7 });
    try {
      navigate(RoutePath[ROUTE_NAME.CHOOSE_COMPANY]);
    } catch (error) {
      Cookies.remove(COOKIE.TOKEN, { path: "/" });
      errorLogger(error);
    }
  };

  const validateError = () => {
    const { error } = loginSchema.validate(loginForm, { abortEarly: false });
    if (error) {
      const errors = error.details.reduce(
        (acc: any, err: ValidationErrorItem) => {
          return { ...acc, [err.path[0]]: err.message };
        },
        {}
      );
      setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return true;
    }
    return false;
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setIsLoadingGoogle(false);
      loginGoogleMutation.mutate(
        { ...tokenResponse },
        {
          onSuccess: (response) => {
            const loginResponse = response.data;
            handleOnSuccessLogin(loginResponse.data.token);
          },
          onError: (error: any) => {
            errorLogger(error);
            enqueueSnackbar({
              ...DEFAULT_SNACKBAR_PROPS,
              variant: "error",
              message: `Terjadi kesalahan! ${JSON.stringify(
                error?.response?.data?.message ||
                  "Mohon coba beberapa saat lagi"
              )}`,
            });
          },
        }
      );
    },
    onError: (error) => {
      setIsLoadingGoogle(false);
      errorLogger(error);
    },
    flow: "auth-code",
  });

  const isLoading =
    loginGoogleMutation.isLoading ||
    loginMutation.isLoading ||
    isLoadingGoogle ||
    isLoadingCompanies;

  const handleOnChange = (type: keyof LoginPayload) => (e: any) => {
    setLoginForm((prev) => ({
      ...prev,
      [type]: e.target.value,
    }));
  };
  const handleBlur = (field: keyof LoginPayload) => () => {
    const { error } = Joi.object({
      [field]: loginSchema.extract(field),
    }).validate({ [field]: loginForm[field] });
    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error.message,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };

  const handleManualLogin = () => {
    const hasError = validateError();
    if (!hasError) {
      loginMutation.mutate(
        { ...loginForm },
        {
          onSuccess: (response) => {
            const loginResponse = response.data;
            dispatch(setUserData({ userId: loginResponse.data.userID }));
            handleOnSuccessLogin(loginResponse.data.token);
          },
          onError: (error: any) => {
            errorLogger(error);
            if (
              error?.response?.status === 400 &&
              error?.response?.data?.message === "unverified-user"
            ) {
              navigate(
                RoutePath[ROUTE_NAME.VERIFY_EMAIL] +
                  `/?email=${loginForm.email}`
              );
            } else {
              enqueueSnackbar({
                ...DEFAULT_SNACKBAR_PROPS,
                variant: "error",
                message: `Terjadi kesalahan! ${JSON.stringify(
                  error?.response?.data?.message ||
                    "Mohon coba beberapa saat lagi"
                )}`,
              });
            }
          },
        }
      );
    }
  };

  const handleLoginGoogle = () => {
    setIsLoadingGoogle(true);
    loginGoogle();
    setIsLoadingGoogle(false);
  };

  const handleContinueToHomePage = useCallback(() => {
    setIsOpenBusinessDialog(false);
  }, []);

  const _renderDialog = useCallback(() => {
    const businessList = businessListData || [];

    return (
      <BusinessDialog
        title={`Pilih akun bisnis mu ${isLoadingCompanies ? "loading.." : ""}`}
        open={isOpenBusinessDialog}
        selectedValue={companyId}
        list={businessList.map((data) => ({
          primaryText: data.name,
          src: data.logoSignedURL || NeemaLogoPNG,
          value: data.companyID,
        }))}
        onClose={(value) => {
          if (value && userToken) {
            Cookies.set(COOKIE.TOKEN, userToken, { expires: 7 });
            dispatch(setUserData({ companyId: value as string }));
            handleContinueToHomePage();
            navigate(RoutePath[ROUTE_NAME.HOME]);
          }
        }}
        onOpen={() => {}}
      />
    );
  }, [
    businessListData,
    companyId,
    dispatch,
    handleContinueToHomePage,
    isLoadingCompanies,
    isOpenBusinessDialog,
    navigate,
    userToken,
  ]);

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleManualLogin();
      }}
      sx={{
        px: 3,
        py: 5,
      }}
    >
      {_renderDialog()}
      <Box height="20px" mb={3}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/neema-b7c3b.appspot.com/o/public-web-assets%2FLogo.png?alt=media&token=3ddd51e9-0144-40a6-a29e-6e23f91cda8c"
          alt="neema-logo"
          className="img-default"
          style={{ width: "fit-content" }}
        />
      </Box>
      <Typography variant="h4" fontWeight={600}>
        Selamat Datang!
      </Typography>
      <Typography variant="h6" fontWeight={300} sx={{ mb: 5 }}>
        Silahkan masuk kembali ke akun Anda.
      </Typography>
      <TextInput
        title="Alamat Email"
        required
        disabled={isLoading}
        textInputProps={{
          placeholder: "Ketik alamat email",
          value: loginForm.email,
          onChange: handleOnChange("email"),
          onBlur: handleBlur("email"),
          autoComplete: "email",
        }}
        error={errors.email !== undefined}
        helper={{
          color: COLOR.danger500,
          text: errors.email,
        }}
        startEndorment={<MailIcon color={COLOR.neutral400} />}
      />
      <TextInput
        sx={{ mt: 2 }}
        title="Kata Sandi"
        required
        disabled={isLoading}
        startEndorment={<LockIcon color={COLOR.neutral400} />}
        endEndorment={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setShowPassword((show) => !show)}
          >
            {showPassword ? (
              <EyeIcon color={COLOR.neutral400} />
            ) : (
              <EyeClosedIcon color={COLOR.neutral400} />
            )}
          </Box>
        }
        textInputProps={{
          type: showPassword ? "text" : "password",
          placeholder: "Ketik kata sandi",
          value: loginForm.password,
          onChange: handleOnChange("password"),
          onBlur: handleBlur("password"),
          autoComplete: "current-password",
        }}
        error={errors.password !== undefined}
        helper={{
          color: COLOR.danger500,
          text: errors.password,
        }}
      />
      <Box display="flex" justifyContent="flex-end" mt={2} mb={5}>
        <OpacityButton
          component="span"
          onClick={() =>
            navigate(RoutePath[ROUTE_NAME.REQUEST_FORGOT_PASSWORD])
          }
        >
          <Typography textAlign="right">Lupa Kata Sandi?</Typography>
        </OpacityButton>
      </Box>
      <CustomButton
        type="submit"
        fullWidth
        disabled={isLoading}
        onClick={(e) => {
          e.preventDefault();
          handleManualLogin();
        }}
      >
        Login
      </CustomButton>

      {/* <Typography textAlign="center" sx={{ my: 3 }}>
        Atau
      </Typography>

      <CustomButton
        fullWidth
        variant="outlined"
        disabled={isLoading}
        onClick={() => {
          handleLoginGoogle();
        }}
      >
        Masuk Dengan Google 🚀
      </CustomButton> */}

      {/* <Typography textAlign="center" sx={{ mt: 5 }}>
        Belum memiliki akun?{" "}
        <Typography
          color={COLOR.primary500}
          fontWeight={600}
          component="span"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (!isLoading) navigate(RoutePath[ROUTE_NAME.REGISTER]);
          }}
        >
          Daftar
        </Typography>
      </Typography> */}
    </Box>
  );
};

export default Login;
