import { PublicScheduleDetail } from "../api/request.types";
import { DURATION_PERIOD, TAB_ENUM } from "../utils/constant";

export type ValueOf<T> = T[keyof T];
export interface FormBaseProps {
  title?: string;
  required?: boolean;
  helper?: {
    text?: string;
    color?: string;
  };
}

export interface IconSVGProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export interface TimeSlot {
  startTime: string; // Format: "HH.mm A"
  endTime: string; // Format: "HH.mm A"
  price: number;
}
// DAY , Timeslot
export type Schedule = Record<string, TimeSlot[]>;

export type SchedulePriceType = {
  time: {
    startTime: string;
    endTime: string;
    disabled: boolean;
  };
  price: number;
};

export type ProductData = {
  images: {
    base64: string;
    file: File | null;
  }[];
  name: string;
  price: number;
  duration: number;
  durationUnit: DURATION_PERIOD | string;
  quota: number;
  isCustomPrice: boolean;
  schedules: Schedule;
  description: string;
  location: {
    lat: number;
    long: number;
    search: string;
  };
  locationDetail: string;
  allowReschedule: boolean;
};

export type EditProductData = {
  images: {
    base64: string;
    file: File | null;
    url: string | null;
  }[];
  name: string;
  price: number;
  duration: number;
  durationUnit: DURATION_PERIOD | string;
  quota: number;
  isCustomPrice: boolean;
  schedules: Schedule;
  description: string;
  location: {
    lat: number;
    long: number;
    search: string;
    isCustomLocation: boolean;
  };
  locationDetail: string;
  allowReschedule: boolean;
};


export interface DefaultTemplatePropsI {
  data: ProductData;
}

export type GoogleMapSrcOptions = {
  address?: string;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
};

export enum PAYMENT_STATUS {
  PAID = "paid",
  REQUEST_REFUND = "request_refund",
  REFUNDED = "refunded",
  REFUND_CANCELLED = "refund_cancelled",
  CANCELLED = "cancelled",
  UNPAID = "unpaid",
  PENDING = "pending",
  PARTIAL_REFUND = "partial_refund",
}

export enum TRANSACTION_STATUS {
  COMPLETE = "complete", // Selesai
  ON_PROGRESS = "on-progress", // Sedang berjalan
  COMING_SOON = "coming-soon", // Akan Datang
  CANCELED = "cancelled", // Batal
  CREATED = "created", // Baru dibuat tp blm bayar
}

export enum SCHEDULE_TYPE {
  DEFAULT = 'default',
};

export type CurrencyCodeType = "IDR" | "USD" | "EUR" | "GBP" | "JPY" | "AUD";

export type PublicScheduleDetailWithQty = PublicScheduleDetail & {
  qty: number;
};

export type IMAGE_USAGE = 'product';



export type ProductTabType = {
  id: TAB_ENUM;
  title: string;
  icon: React.ReactElement;
}