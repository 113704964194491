import { forwardRef } from 'react';
import { BottomSheet, BottomSheetProps, BottomSheetRef } from 'react-spring-bottom-sheet';
import { Box, Typography } from '@mui/material';
import { MAIN_PRODUCT_TYPE, PRODUCT_TYPE } from '../../utils/constant';
import { COLOR } from '../../utils/color';
import OpacityButton from '../OpacityButton/OpacityButton';
import ProductLinkImg from '../../assets/images/product/product-link.png';
import ProductReservationImg from '../../assets/images/product/product-calendar.png';

interface ChooseProductTypeBottomSheetProps {
  bottomSheetProps?: Omit<BottomSheetProps, 'children' | 'open' | 'ref'>;
  open: boolean;
  onClick: (type: MAIN_PRODUCT_TYPE) => void;
};
const ChooseProductTypeBottomSheet = forwardRef<BottomSheetRef, ChooseProductTypeBottomSheetProps>(
  ({ bottomSheetProps, ...props }, ref) => {
    const getProductTypeConfig = (type: MAIN_PRODUCT_TYPE) => {
      switch (type) {
        case PRODUCT_TYPE.RESERVATION:
          return {
            title: 'Reservasi',
            src: ProductReservationImg,
            description: 'Penjadwalan Reservasi dengan Pembayaran'
          };
        case PRODUCT_TYPE.LINK:
          return {
            title: 'Link',
            src: ProductLinkImg,
            description: 'Mengarahkan pengguna ke link external'
          };
      }
    };


    return (
      <BottomSheet
        ref={ref}
        open={props.open}
        {...bottomSheetProps}
      >
        <Box display="flex" flexDirection="column" width="100%" height="100%" pb={5}>
          <Typography px={2} mb={2} fontWeight={600} fontSize={18}>Pilih Tipe Produk</Typography>
          <Box display="flex" flexDirection="column" >
            {Object.values([PRODUCT_TYPE.LINK, PRODUCT_TYPE.RESERVATION]).map((type) => {
              const productConfig = getProductTypeConfig(type as MAIN_PRODUCT_TYPE);
              return (
                <OpacityButton 
                padding="auto" 
                p={2} 
                display="flex" 
                flexDirection="row" 
                gap={2} 
                alignItems="center" 
                textAlign="left" 
                onClick={(e) => props.onClick(type as MAIN_PRODUCT_TYPE)}>
                  <Box sx={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    flexShrink: 0,
                  }}>
                    <img
                      src={productConfig.src}
                      className="img-default"
                      alt={`${productConfig.title}-img`}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography fontWeight={600}>{productConfig.title}</Typography>
                    <Typography color={COLOR.neutral400}>{productConfig.description}</Typography>
                  </Box>
                </OpacityButton>
              )
            })}
          </Box>
        </Box>
      </BottomSheet>
    );
  }
);

export default ChooseProductTypeBottomSheet;
