import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TEMPLATE_HEX_COLOR, TIMEZONES } from "../../utils/constant";
import { PageTemplateType, SocialMediaType } from "../../api/request.types";

export type BusinessDataType = {
  url: string;
  name: string;
  description: string;
  location: {
    lat: number;
    long: number;
    search: string;
    useManualLocation: boolean;
  };
  timezone: string;
  locationDetail: string;
  image?: string;
  templateId: PageTemplateType;
  color: string;
  socialMedia: SocialMediaType;
};

export type BusinessState = {
  data: BusinessDataType;
  editData: BusinessDataType;
};

export const initialState: BusinessState = {
  editData: {
    url: "",
    name: "",
    description: "",
    location: {
      lat: 0,
      long: 0,
      search: "",
      useManualLocation: false,
    },
    locationDetail: "",
    timezone: TIMEZONES[0],
    image: undefined,
    templateId: "Type1",
    color: TEMPLATE_HEX_COLOR[0].primary,
    socialMedia: {
      tiktok: "",
      instagram: "",
      youtube: "",
      email: "",
      discord: "",
      twitter: "",
      twitch: "",
      facebook: "",
      snapchat: "",
      linkedin: "",
      pinterest: "",
      vimeo: "",
      etsy: "",
      link: "",
    },
  },
  data: {
    url: "",
    name: "",
    description: "",
    location: {
      lat: 0,
      long: 0,
      search: "",
      useManualLocation: false,
    },
    locationDetail: "",
    timezone: TIMEZONES[0],
    image: undefined,
    templateId: "Type1",
    color: TEMPLATE_HEX_COLOR[0].primary,
    socialMedia: {
      tiktok: "",
      instagram: "",
      youtube: "",
      email: "",
      discord: "",
      twitter: "",
      twitch: "",
      facebook: "",
      snapchat: "",
      linkedin: "",
      pinterest: "",
      vimeo: "",
      etsy: "",
      link: "",
    },
  },
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessData: (
      state,
      action: PayloadAction<Partial<BusinessDataType>>
    ) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          location: {
            ...state.data.location,
            ...action.payload.location,
          },
        },
      };
    },
    resetBusinessData: (state) => {
      return initialState;
    },
    setEditBusinessData: (
      state,
      action: PayloadAction<Partial<BusinessDataType>>
    ) => {
      return {
        ...state,
        editData: {
          ...state.editData,
          ...action.payload,
          location: {
            ...state.editData.location,
            ...action.payload.location,
          },
          socialMedia: {
            ...state.editData.socialMedia,
            ...action.payload.socialMedia,
          },
        },
      };
    },
    resetEditBusinessData: (state) => {
      return initialState;
    },
  },
});

export const {
  setBusinessData,
  resetBusinessData,
  setEditBusinessData,
  resetEditBusinessData,
} = businessSlice.actions;
// export const { setEditBusinessData, resetEditBusinessData } =
//   editBusinessSlice.actions;
export default businessSlice.reducer;
