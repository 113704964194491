import React from "react";
import { Box, Typography } from "@mui/material";
import Joi from "joi";
import { useNavigate } from "react-router-dom";
import { requestForgotPasswordSchema } from "../joiSchema/auth";
import TextInput from "../components/Forms/TextInput/TextInput";
import CustomButton from "../components/CustomButton/CustomButton";
import { COLOR } from "../utils/color";
import AppContainer from "../components/Layout/AppContainer/AppContainer";
import { FaArrowLeft } from "react-icons/fa";
import SubMenuHeader from "../components/Layout/SubMenuHeader/SubMenuHeader";
import MailIcon from "../assets/svg/MailIcon";
import { useRequestForgotPassword } from "../query/mutations";
import { errorLogger } from "../utils/logger";
import { enqueueSnackbar } from "notistack";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../utils/constant";
import { isAxiosError } from "axios";

type RequestForgotPasswordType = {
  email: string;
};

const RequestForgotPassword = () => {
  const isLoading = false;
  const navigate = useNavigate();

  const [emailForm, setEmailForm] = React.useState<RequestForgotPasswordType>({
    email: "",
  });

  const [errors, setErrors] = React.useState<
    Record<keyof RequestForgotPasswordType, string | undefined>
  >({
    email: undefined,
  });
  const requestForgotPasswordMutation = useRequestForgotPassword();

  const handleOnChange =
    (type: keyof RequestForgotPasswordType) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmailForm((prev) => ({
        ...prev,
        [type]: e.target.value,
      }));
    };

  const handleBlur = (field: keyof RequestForgotPasswordType) => () => {
    const { error } = Joi.object({
      [field]: requestForgotPasswordSchema.extract(field),
    }).validate({ [field]: emailForm[field] });
    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error.message,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { error } = requestForgotPasswordSchema.validate(emailForm, {
      abortEarly: false,
    });

    if (error) {
      const validationErrors: Record<keyof RequestForgotPasswordType, string> =
        {} as any;
      error.details.forEach((detail) => {
        const key = detail.path[0] as keyof RequestForgotPasswordType;
        validationErrors[key] = detail.message;
      });
      setErrors(validationErrors);
      return;
    }
    try {
      await requestForgotPasswordMutation.mutateAsync(emailForm);
      enqueueSnackbar(`Reset Password Email has been sent`, {
        variant: "success",
        ...DEFAULT_SNACKBAR_PROPS,
      });
      navigate(RoutePath[ROUTE_NAME.LOGIN]);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        const msg = `Email tidak ditemukan`;

        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: msg,
        });
      } else if (isAxiosError(error) && error.response?.status === 400) {
        const msg = `Token sudah expired. Silakan mengajukan permintaan ulang`;

        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: msg,
        });
      } else {
        errorLogger(error);
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
        });
      }
    }
  };

  return (
    <AppContainer>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(-1);
          },
        }}
        text={"Ajukan Email Perubahan Kata Sandi"}
      />
      <Box py={3} px={3}>
        <Typography variant="h5" fontWeight="bold">
          Detail Akun Kamu
        </Typography>
        <Box component="form" onSubmit={handleOnSubmit}>
          <TextInput
            sx={{ mt: 2 }}
            title="Email"
            required
            disabled={isLoading}
            startEndorment={<MailIcon color={COLOR.neutral400} />}
            textInputProps={{
              type: "email",
              placeholder: "Masukkan Email",
              value: emailForm.email,
              onChange: handleOnChange("email"),
              onBlur: handleBlur("email"),
              autoComplete: "email",
            }}
            error={errors.email !== undefined}
            helper={{
              color: COLOR.danger500,
              text: errors.email,
            }}
          />

          <CustomButton sx={{ mt: 4 }} type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Ajukan Perubahan Kata Sandi"}
          </CustomButton>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default RequestForgotPassword;
