import { Box, Typography } from "@mui/material";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import { IoIosCloseCircle } from "react-icons/io";
import { COLOR } from "../../utils/color";
import { GoPlusCircle } from "react-icons/go";
import { FirstImageContainer, FirstImageRelativeContainer, IconContainerStyle, ImageStyle, SmallImageContainer, SmallImageRelativeContainer } from "./ImageComponent.styles";


interface FirstImageComponentProps {
  image: string;
  onClickClose?: React.MouseEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export const FirstImageComponent: React.FC<FirstImageComponentProps> = ({
  image,
  onClickClose = () => { },
  onClick = () => { }
}) => (
  <Box sx={FirstImageRelativeContainer}>
    {image ? (
      <>
        <ImageRenderer src={image} alt="preview" />
        <Box sx={IconContainerStyle} onClick={onClickClose}>
          <IoIosCloseCircle
            size={20}
            color={COLOR.neutral600}
          />
        </Box>
      </>
    ) : (
      <Box
        sx={FirstImageContainer}
        onClick={onClick}
      >
        <GoPlusCircle color={COLOR.primary500} size={48} />
        <Typography color={COLOR.neutral400}>
          Tambah Gambar
        </Typography>
      </Box>
    )}
  </Box>
);

interface SmallImageComponentProps {
  index: number;
  image: string;
  onClickClose?: React.MouseEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const SmallImageComponent: React.FC<SmallImageComponentProps> = ({
  image,
  onClickClose = () => { },
  onClick = () => { },
}) => (
  <Box sx={SmallImageRelativeContainer}>
    {image.length === 0 ? (
      <Box
        sx={SmallImageContainer}
        onClick={onClick}
      >
        <GoPlusCircle color={COLOR.primary500} size={24} />
      </Box>
    ) : (
      <>
        <ImageRenderer src={image} alt="preview" style={ImageStyle} />
        <Box sx={IconContainerStyle} onClick={onClickClose}>
          <IoIosCloseCircle
            size={20}
            color={COLOR.neutral600}
          />
        </Box>
      </>
    )}
  </Box>
);
