import { Box, Typography } from "@mui/material";
import {
  EventItemContainerSx,
  EventItemDateSx,
  EventItemDetail,
  EventNameStatusDetail,
  EventRightSectionSx,
  EventStatusSectionSx,
  FaCaretRightPosition,
} from "./EventItem.styles";
import { COLOR } from "../../utils/color";
import OpacityButton from "../OpacityButton/OpacityButton";
import { FaCaretRight } from "react-icons/fa";
import StatusText from "../StatusText/StatusText";
import { Dayjs } from "dayjs";
import { TimezoneProps } from "@mui/x-date-pickers";

interface EventItemPropsI {
  onClick?: () => void;
  disabled?: boolean;
  status: "in-progress" | "done" | "upcoming";
  date: Dayjs;
  productName: string;
  startTime: string;
  endTime: string;
  timezone: string;
  qty: number | string;
  unit?: string;
  customerName: string;
}
const EventItem = (props: EventItemPropsI) => {
  const { onClick, disabled, unit = "Pax" } = props;
  const _getStatusProps = (
    _stat: EventItemPropsI["status"]
  ): { color: string; backgroundColor: string; text: string } => {
    switch (_stat) {
      case "in-progress":
        return {
          color: COLOR.warning500,
          backgroundColor: COLOR.warning50,
          text: "Sedang Berlangsung",
        };
      case "done":
        return {
          color: COLOR.success500,
          backgroundColor: COLOR.success50,
          text: "Telah Berakhir",
        };
      default:
      case "upcoming":
        return {
          color: COLOR.neutral500,
          backgroundColor: COLOR.neutral50,
          text: "Akan Datang",
        };
    }
  };

  const statusProps = _getStatusProps(props.status);
  return (
    <OpacityButton
      sx={EventItemContainerSx}
      onClick={onClick}
      disabled={disabled}
    >
      <Box sx={EventItemDateSx}>
        <Typography variant="caption" fontSize={12} lineHeight="14px">
          {props.date.format("MMM")}
        </Typography>
        <Typography
          variant="caption"
          fontWeight="bold"
          fontSize={18}
          lineHeight="20px"
        >
          {props.date.format("DD")}
        </Typography>
      </Box>
      <Box sx={EventRightSectionSx}>
        <Box sx={EventItemDetail}>
          <Typography variant="body1" fontWeight="bold" fontSize={16}>
            {props.productName}
          </Typography>
          <Box sx={EventStatusSectionSx}>
            <Box sx={EventNameStatusDetail}>
              <Typography variant="body1" fontWeight={400} fontSize={14}>
                {props.customerName}
              </Typography>
              <StatusText
                color={statusProps.color}
                backgroundColor={statusProps.backgroundColor}
              >
                {statusProps.text}
              </StatusText>
            </Box>
            <Box sx={FaCaretRightPosition}>
              <FaCaretRight />
            </Box>
          </Box>
          <Typography variant="body1" fontSize={12}>
            {props.startTime} - {props.endTime} {props.timezone}
            <Typography variant="caption" color={COLOR.neutral500} ml={"4px"}>
              (1 {unit})
            </Typography>
          </Typography>
        </Box>
      </Box>
    </OpacityButton>
  );
};

export default EventItem;
