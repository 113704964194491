import React from 'react';
import AppContainer from '../../../components/Layout/AppContainer/AppContainer';
import { ContainerStyle } from '../../../styles/global.styles';
import { Box, Typography } from '@mui/material';
import SubMenuHeader from '../../../components/Layout/SubMenuHeader/SubMenuHeader';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import SchedulePrice from '../../../components/SchedulePrice/SchedulerPrice';
import { ManageSchedulesType, useManageSchedule } from '../../../context/ScheduleContext';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { Schedule } from '../../../types/globalTypes';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { setScheduleData } from '../../../redux/reducers/product';
import { RootReducerState } from '../../../redux/reducers';
import { ROUTE_NAME, RoutePath } from '../../../utils/constant';
import { FooterContainer } from '../../ProductDetail/ProductDetail.styles';

const ManageSchedule: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: productData } = useSelector((state: RootReducerState) => state.productReducer);

  const { handleSchedulesChange, schedules } = useManageSchedule();
  const convertSchedule = (managedSchedule: ManageSchedulesType): Schedule => {
    return Object.keys(managedSchedule).reduce((acc: Schedule, day) => {
      const slots = managedSchedule[day]
        .filter(item => !item.time.disabled) // Exclude disabled time slots
        .map(item => ({
          startTime: dayjs(item.time.startTime).format('HH.mm'),
          endTime: dayjs(item.time.endTime).format('HH.mm'),
          price: item.price,
        }));

      if (slots.length > 0) {
        acc[day] = slots;
      }

      return acc;
    }, {});
  };
  return (
    <AppContainer
      bottomChildren={
        <Box sx={FooterContainer}>
          <CustomButton
            fullWidth
            onClick={() => {
              let productSchedule = convertSchedule(schedules);
              dispatch(setScheduleData(productSchedule))
              navigate(RoutePath[ROUTE_NAME.ADD_PRODUCT], { replace: true });
            }}
          >
            <Typography variant="body1" fontWeight={500}>
              Simpan
            </Typography>
          </CustomButton>
        </Box>
      }>
      <Box sx={ContainerStyle}>
        <SubMenuHeader
          leftNav={{
            icon: <FaArrowLeft />,
            onClick: () => navigate(-1),
          }}
          text={"Atur Jadwal"}
        />

        <Box sx={ContainerStyle}>
          <Box sx={{
            px: 3,
            py: 3,
            width: '100%',
          }}>
            {Object.keys(schedules).map(day => {
              const daySchedules = schedules[day];
              return (
                <SchedulePrice
                  key={day}
                  title={day}
                  schedules={daySchedules}
                  onSchedulesChange={handleSchedulesChange(day)}
                  priceProps={{
                    isCustomPrice: productData.isCustomPrice,
                    price: productData.price,
                  }}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default ManageSchedule;