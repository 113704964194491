import { Theme } from "@emotion/react";
import { Box, BoxProps, SxProps } from "@mui/material";
import { DefaultInputStyle, ContainerStyle, DisabledContainerStyle, EndormentStyle, ErrorContainerStyle, InputStyle, PasswordInputStyle } from "../form.styles";
import InputTitle from "../InputTitle/InputTitle";
import HelperText from "../HelperText/HelperText";
import { FormBaseProps } from "../../../types/globalTypes";
import { CSSProperties } from "react";

interface TextInputPropsI extends BoxProps, FormBaseProps {
  startEndorment?: React.ReactNode;
  endEndorment?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  textInputProps?: {
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    name?: string;
    style?: CSSProperties;
    placeholder?: string;
    type?: React.HTMLInputTypeAttribute;
  } & React.InputHTMLAttributes<HTMLInputElement>;
  additionalPrefix?: React.ReactNode;
};

const TextInput: React.FC<TextInputPropsI> = (props) => {
  const { sx } = props;

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
      <InputTitle
        title={props.title}
        required={props.required}
        textProps={{ sx: { mb: 1 } }}
        prefix={props?.additionalPrefix}
      />
      <Box sx={({
        ...ContainerStyle,
        ...(props.disabled ? DisabledContainerStyle : {}),
        ...(props.error ? ErrorContainerStyle : {}),
      }) as SxProps<Theme>}>
        {props.startEndorment && <Box
          component="span"
          sx={{ ...EndormentStyle, mr: 1 }}
        >
          {props.startEndorment}
        </Box>}
        <input
          {...props.textInputProps}
          disabled={props.disabled}
          style={{
            ...InputStyle,
            ...(
              (props.textInputProps?.type === 'password' &&
                (props.textInputProps?.value as string)?.length > 0) ?
                PasswordInputStyle : DefaultInputStyle
            ),
            ...props?.textInputProps?.style,
          }}
        />
        {props.endEndorment && <Box
          component="span"
          sx={{ ...EndormentStyle, ml: 1 }}
        >
          {props.endEndorment}
        </Box>}
      </Box>
      <HelperText {...props.helper} />
    </ Box>
  )
};

export default TextInput;