import React from "react";
import { Box, Typography } from "@mui/material";
import Joi from "joi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { forgotPasswordSchema } from "../joiSchema/auth";
import EyeIcon from "../assets/svg/EyeOpenIcon";
import EyeClosedIcon from "../assets/svg/EyeClosedIcon";
import LockIcon from "../assets/svg/LockIcon";
import TextInput from "../components/Forms/TextInput/TextInput";
import CustomButton from "../components/CustomButton/CustomButton";
import { COLOR } from "../utils/color";
import AppContainer from "../components/Layout/AppContainer/AppContainer";
import { FaArrowLeft } from "react-icons/fa";
import SubMenuHeader from "../components/Layout/SubMenuHeader/SubMenuHeader";
import { useResetPassword } from "../query/mutations";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../utils/constant";
import { errorLogger } from "../utils/logger";

type ForgotPasswordType = {
  new: string;
  retype: string;
};

const ForgotPassword = () => {
  const isLoading = false;
  const navigate = useNavigate();

  const [passwordForm, setPasswordForm] = React.useState<ForgotPasswordType>({
    new: "",
    retype: "",
  });

  const [showPassword, setShowPassword] = React.useState<
    Record<keyof ForgotPasswordType, boolean>
  >({
    new: false,
    retype: false,
  });

  const [errors, setErrors] = React.useState<
    Record<keyof ForgotPasswordType, string | undefined>
  >({
    new: undefined,
    retype: undefined,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const useForgotPasswordMutation = useResetPassword();

  const handleOnChange =
    (type: keyof ForgotPasswordType) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPasswordForm((prev) => ({
        ...prev,
        [type]: e.target.value,
      }));
    };

  const handleBlur = (field: keyof ForgotPasswordType) => () => {
    if (field === "retype") {
      if (passwordForm[field].length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "tidak boleh kosong",
        }));
      } else if (passwordForm[field] !== passwordForm["new"]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "Kata sandi anda tidak sama",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: undefined,
        }));
      }
    } else {
      const { error } = Joi.object({
        [field]: forgotPasswordSchema.extract(field),
      }).validate({ [field]: passwordForm[field] });
      if (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: error.message,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: undefined,
        }));
      }
    }
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { error } = forgotPasswordSchema.validate(passwordForm, {
      abortEarly: false,
    });

    if (error) {
      const validationErrors: Record<keyof ForgotPasswordType, string> =
        {} as any;
      error.details.forEach((detail) => {
        const key = detail.path[0] as keyof ForgotPasswordType;
        validationErrors[key] = detail.message;
      });
      setErrors(validationErrors);
      return;
    }
    try {
      await useForgotPasswordMutation.mutateAsync({
        token: token || "",
        email: email || "",
        password: passwordForm.new,
      });
      enqueueSnackbar(`Password has been Reset`, {
        variant: "success",
        ...DEFAULT_SNACKBAR_PROPS,
      });
      navigate(RoutePath[ROUTE_NAME.LOGIN]);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        const msg = `Email tidak valid`;

        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: msg,
        });
      } else if (isAxiosError(error) && error.response?.status === 400) {
        const msg = error.response?.data?.message || "";
        let shownMessage =
          "Terjadi kesalahan, mohon coba dalam beberapa saat lagi";
        if (msg === "Invalid Token") shownMessage = "Token tidak valid";
        if (msg === "Token has expired")
          shownMessage =
            "Token sudah expired. Silakan mengajukan permintaan ulang";
        if (msg === "User has been verified") shownMessage = "User sudah aktif";

        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: shownMessage,
        });
      } else {
        errorLogger(error);
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
        });
      }
    }
  };

  const togglePasswordVisibility = (field: keyof ForgotPasswordType) => () => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <AppContainer>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(-1);
          },
        }}
        text={"Atur Kata Sandi Baru"}
      />
      <Box py={3} px={3}>
        <Typography variant="h5" fontWeight="bold">
          Atur Kata Sandi
        </Typography>
        <Box component="form" onSubmit={handleOnSubmit}>
          <TextInput
            sx={{ mt: 2 }}
            title="Kata Sandi Baru"
            required
            disabled={isLoading}
            startEndorment={<LockIcon color={COLOR.neutral400} />}
            endEndorment={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={togglePasswordVisibility("new")}
              >
                {showPassword.new ? (
                  <EyeIcon color={COLOR.neutral400} />
                ) : (
                  <EyeClosedIcon color={COLOR.neutral400} />
                )}
              </Box>
            }
            textInputProps={{
              type: showPassword.new ? "text" : "password",
              placeholder: "Ketik kata sandi baru",
              value: passwordForm.new,
              onChange: handleOnChange("new"),
              onBlur: handleBlur("new"),
              autoComplete: "new-password",
            }}
            error={errors.new !== undefined}
            helper={{
              color: COLOR.danger500,
              text: errors.new,
            }}
          />

          <TextInput
            sx={{ mt: 2 }}
            title="Ulangi Kata Sandi Baru"
            required
            disabled={isLoading}
            startEndorment={<LockIcon color={COLOR.neutral400} />}
            endEndorment={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={togglePasswordVisibility("retype")}
              >
                {showPassword.retype ? (
                  <EyeIcon color={COLOR.neutral400} />
                ) : (
                  <EyeClosedIcon color={COLOR.neutral400} />
                )}
              </Box>
            }
            textInputProps={{
              type: showPassword.retype ? "text" : "password",
              placeholder: "Ketik ulang kata sandi baru",
              value: passwordForm.retype,
              onChange: handleOnChange("retype"),
              onBlur: handleBlur("retype"),
              autoComplete: "new-password",
            }}
            error={errors.retype !== undefined}
            helper={{
              color: COLOR.danger500,
              text: errors.retype,
            }}
          />

          <CustomButton sx={{ mt: 4 }} type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Simpan Kata Sandi"}
          </CustomButton>
        </Box>
      </Box>
    </AppContainer>
  );
};

export default ForgotPassword;
