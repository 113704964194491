import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Typography from "@mui/material/Typography";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DEFAULT_SNACKBAR_PROPS,
  FONT_COLOR,
  ROUTE_NAME,
  RoutePath,
  SX_P_M_SIZE,
  TEMPLATE_HEX_COLOR,
  TEMPLATE_PAGE,
} from "../../utils/constant";
import { COLOR } from "../../utils/color";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import { useGetCompanyByIdV2 } from "../../query/queries";
import ProfileAvatar from "./components/ProfileAvatar/ProfileAvatar";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import NeemaLogoPNG from "../../assets/images/neema-logo.png";
import GearIcon from "../../assets/svg/GearIcon";
import {
  FaPalette,
  FaPencilAlt,
  FaPlus,
  FaStore,
  FaTrash,
} from "react-icons/fa";
import { BusinessTemplateData } from "./PageTemplate/PageTemplate";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../../utils/logger";
import CustomButton from "../../components/CustomButton/CustomButton";
import {
  PRODUCT_TYPE,
  PageTemplateType,
  ProductDetailType,
} from "../../api/request.types";
import { openDialog } from "../../redux/reducers/confirmationDialog";
import {
  useCreateProductSection,
  useDeleteProductById,
  useUpdateCompanyProductDetailV2,
  useUpdateCompanyV2,
  useUpdateProductSectionV2,
} from "../../query/mutations";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PhonePreview from "../../components/PhonePreview/PhonePreview";
import { DeleteBtnSx, EditBtnSx } from "./Business.styles";
import ChooseProductTypeBottomSheet from "../../components/BottomSheets/ChooseProductTypeBottomSheet";
import SectionBottomSheet from "../../components/BottomSheets/SectionBottomSheet";
import CustomColorPicker from "../../components/CustomColorPicker/CustomColorPicker";
import TemplateHome from "../../components/TemplateHome/TemplateHome";
import { NEW_PRODUCT_NAV } from "./Product/Product";
import { MdDragIndicator } from "react-icons/md";

enum TAB_ENUM {
  DESIGN = "design",
  PROFILE = "profile",
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Business: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const tabQuery = searchParams.get("tab") as TAB_ENUM | null;

  const [tabValue, setTabValue] = React.useState<TAB_ENUM>(TAB_ENUM.PROFILE);
  const [openProductSheet, setOpenProductSheet] =
    React.useState<boolean>(false);
  const [openSectionSheet, setOpenSectionSheet] =
    React.useState<boolean>(false);
  const [editSectionProductID, setEditSectionProductID] =
    React.useState<string>("new");
  const [editSectionProductName, setEditSectionProductName] =
    React.useState<string>("");

  const companyId = userReducer.data.companyId;
  const companyV2Query = useGetCompanyByIdV2(companyId || "");
  const updateCompanyProductDetailMutation = useUpdateCompanyProductDetailV2();
  const updateProductSectionMutation = useUpdateProductSectionV2();
  const createProductSectionMutation = useCreateProductSection();
  // const companyQuery = useGetCompanyById(companyId || "");
  const companyV2Detail = useMemo(() => {
    return companyV2Query.data?.data?.data;
  }, [companyV2Query.data?.data?.data]);

  const deleteProductByIdMutation = useDeleteProductById();

  const [businessTemplateData, setBusinessTemplateData] =
    useState<BusinessTemplateData>({
      color: companyV2Detail?.color || TEMPLATE_HEX_COLOR[0].primary,
      templateId: (companyV2Detail?.template ||
        TEMPLATE_PAGE[0].id) as PageTemplateType,
    });
  const [productList, setProductList] = useState<ProductDetailType[]>(
    companyV2Detail?.productDetails || []
  );
  const updateCompanyV2Mutation = useUpdateCompanyV2();
  const isLoading = companyV2Query.isLoading;

  useEffect(() => {
    if (!companyV2Query.isLoading && companyV2Query.isFetched) {
      setProductList(companyV2Detail?.productDetails || []);
      setBusinessTemplateData({
        color: companyV2Detail?.color || TEMPLATE_HEX_COLOR[0].primary,
        templateId: (companyV2Detail?.template ||
          TEMPLATE_PAGE[0].id) as PageTemplateType,
      });
    }
  }, [
    companyV2Detail?.color,
    companyV2Detail?.productDetails,
    companyV2Detail?.template,
    companyV2Query.isFetched,
    companyV2Query.isLoading,
  ]);

  useEffect(() => {
    if (tabQuery && Object.values(TAB_ENUM).includes(tabQuery)) {
      setTabValue(tabQuery as TAB_ENUM);
    } else {
      setSearchParams({ tab: tabValue });
    }
  }, [setSearchParams, tabQuery, tabValue]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: TAB_ENUM) => {
    setTabValue(newValue);
    setSearchParams({ tab: newValue });
  };
  const handleDeleteProduct = (productData: ProductDetailType) => {
    dispatch(
      openDialog({
        message: `Apakah anda yakin untuk menghapus "${productData.name}"?`,
        secondaryBtn: {
          onClick: async () => {
            try {
              await deleteProductByIdMutation.mutateAsync({
                productId: productData.productID,
                type: productData.type,
              });
              await companyV2Query.refetch();
              enqueueSnackbar(
                `Product "${productData.name}" deleted successfully`,
                {
                  variant: "success",
                  ...DEFAULT_SNACKBAR_PROPS,
                }
              );
            } catch (error) {
              errorLogger(error);
              enqueueSnackbar(
                "Gagal menghapus product. mohon coba beberapa saat lagi.",
                {
                  ...DEFAULT_SNACKBAR_PROPS,
                  variant: "error",
                }
              );
            }
          },
        },
      })
    );
  };
  const onDragEnd = async (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const reorderedList: ProductDetailType[] = reorder(
      productList,
      source.index,
      destination.index
    );
    setProductList(reorderedList);
    await updateCompanyProductDetailMutation.mutateAsync({
      companyId: companyId || "",
      details: reorderedList.map((data) => ({
        productID: data.productID,
        type: data.type,
        name: data.name,
        status: data.status,
      })),
    });
    await companyV2Query.refetch();
  };

  const _renderProductList = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="productList">
        {(provided: any) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            {productList.map((product, index) => (
              <Draggable
                key={product.productID}
                draggableId={product.productID}
                index={index}
              >
                {(provided: any) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    display="flex"
                    flexDirection="row"
                    border={`1px solid ${COLOR.neutral300}`}
                    justifyContent="space-between"
                    alignItems="center"
                    borderRadius={2}
                    p={1}
                    width="100%"
                    sx={{ backgroundColor: "white" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        sx={{
                          width: "fit-content",
                          aspectRatio: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MdDragIndicator color={COLOR.neutral500} size={20} />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}
                        sx={{ wordBreak: "break-all" }}
                      >
                        <Typography
                          fontWeight={500}
                          color={COLOR.neutral500}
                          variant="caption"
                        >
                          {product.type === PRODUCT_TYPE.SECTION
                            ? ""
                            : `ID: ${product.productID}`}
                        </Typography>
                        <Box display="flex" flexDirection="row">
                          <Typography fontWeight={600}>
                            {product.name}
                          </Typography>
                          {product.status === "draft" && (
                            <Typography
                              alignContent="center"
                              fontSize={10}
                              fontWeight={500}
                              color="red"
                              ml={1}
                            >
                              (DRAFT)
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <CustomButton
                        onClick={() => {
                          if (product.type === PRODUCT_TYPE.SECTION) {
                            setEditSectionProductID(product.productID);
                            setEditSectionProductName(product.name);
                            setOpenSectionSheet(true);
                          } else {
                            navigate(
                              `${RoutePath[ROUTE_NAME.EDIT_PRODUCT]}/${
                                product.productID
                              }/edit?type=${product.type}`
                            );
                          }
                        }}
                        variant="text"
                        sx={EditBtnSx}
                      >
                        <FaPencilAlt size={20} />
                      </CustomButton>
                      <CustomButton
                        onClick={() => handleDeleteProduct(product)}
                        variant="text"
                        sx={DeleteBtnSx}
                      >
                        <FaTrash size={20} />
                      </CustomButton>
                    </Box>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );

  const _renderProfile = () => {
    const renderPreviewContent = (
      <TemplateHome
        mode="preview"
        fontColor={FONT_COLOR}
        color={companyV2Detail?.color}
        logoSignedURL={companyV2Detail?.logoSignedURL}
        name={companyV2Detail?.name}
        description={companyV2Detail?.description}
        socialMedia={companyV2Detail?.socialMedia || {}}
        productDetails={companyV2Detail?.productDetails || []}
        currency={companyV2Detail?.currency}
      />
    );

    return (
      <Box px={2} pt={2} pb={5}>
        {_renderProductList()}
        <Box display="flex" flexDirection="column" gap={1} pt={5}>
          <CustomButton
            sx={{ gap: 1 }}
            startEndorment={<FaPlus />}
            onClick={() => {
              setOpenProductSheet(true);
            }}
          >
            Tambah Produk
          </CustomButton>
          <CustomButton
            variant="text"
            sx={{ gap: 1 }}
            startEndorment={<FaPlus />}
            onClick={() => {
              setOpenSectionSheet(true);
            }}
          >
            Tambah Bagian
          </CustomButton>
        </Box>

        <Box display="flex" alignItems="center" flexDirection="column" py={5}>
          <Typography pb={3} fontWeight={600} variant="h5">
            Preview
          </Typography>
          <PhonePreview>{renderPreviewContent}</PhonePreview>
        </Box>
      </Box>
    );
  };
  const handleUpdateColor = useCallback(async () => {
    try {
      if (companyId) {
        await updateCompanyV2Mutation.mutateAsync({
          companyId,
          color: businessTemplateData.color,
          template: businessTemplateData.templateId,
        });
      }
    } catch (error) {
      errorLogger(error);
    } finally {
      enqueueSnackbar({
        ...DEFAULT_SNACKBAR_PROPS,
        message: "Update Berhasil",
        variant: "success",
      });
      companyV2Query.refetch();
      navigate(RoutePath[ROUTE_NAME.BUSINESS] + `/?tab=${TAB_ENUM.PROFILE}`);
    }
  }, [
    businessTemplateData,
    companyId,
    companyV2Query,
    navigate,
    updateCompanyV2Mutation,
  ]);

  const _renderEditDesign = () => {
    return (
      <Box>
        <Box sx={{ mt: SX_P_M_SIZE }}>
          <Typography variant="h6" fontWeight={600} sx={{ mx: SX_P_M_SIZE }}>
            Pilih Tema Warna
          </Typography>
          <Box px={3} py={2} display="flex">
            <CustomColorPicker
              color={businessTemplateData.color}
              onChangeColor={(color: string) => {
                setBusinessTemplateData({
                  ...businessTemplateData,
                  color,
                });
              }}
            />
          </Box>
          <Typography px={3} py={2} fontWeight={500}>
            Rekomendasi Warna
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              overflowY: "scroll",
              pb: SX_P_M_SIZE,
              "&::-webkit-scrollbar": {
                width: 0,
                height: 2,
              },
            }}
          >
            {TEMPLATE_HEX_COLOR.map((colorPalete, index) => {
              const isSelected =
                colorPalete.primary === businessTemplateData.color;
              return (
                <OpacityButton
                  disableOpacity
                  disabled={isLoading}
                  onClick={() => {
                    setBusinessTemplateData({
                      ...businessTemplateData,
                      color: colorPalete.primary,
                    });
                  }}
                  key={index}
                  sx={{
                    width: "48px",
                    height: "48px",
                    backgroundColor: colorPalete.primary,
                    borderWidth: isSelected ? "4px" : 0,
                    borderStyle: "solid",
                    borderColor: colorPalete.secondary,
                    ml: index === 0 ? SX_P_M_SIZE : 1,
                    mr:
                      index === TEMPLATE_HEX_COLOR.length - 1 ? SX_P_M_SIZE : 1,
                    borderRadius: "50%",
                    flex: "0 0 auto",
                    "&:hover": {
                      opacity: 0.8,
                      cursor: "pointer",
                    },
                  }}
                />
              );
            })}
          </Box>
        </Box>
        {/* <Box sx={TemplateContainerSx}>
          {TEMPLATE_PAGE.map((item, index) => {
            const isSelected = item.id === businessTemplateData.templateId;
            return (
              <OpacityButton
                disableOpacity
                key={item.id}
                onClick={() => {
                  setBusinessTemplateData({
                    ...businessTemplateData,
                    templateId: item.id,
                  });
                }}
              >
                <Box
                  key={index}
                  sx={{
                    ...TemplateImageStyle,
                    border: `4px solid ${isSelected ? COLOR.primary200 : COLOR.neutral200
                      }`,
                    pointerEvents: isLoading ? "none" : "initial",
                  }}
                >
                  <img
                    src="https://placehold.co/500x600/dbeafe/000"
                    className="img-default"
                    alt={item.id}
                    style={{ objectFit: "cover" }}
                  />
                </Box>
                <Typography
                  variant="caption"
                  textAlign="left"
                  color={COLOR.neutral500}
                  textTransform="capitalize"
                >
                  {item.id}
                </Typography>
              </OpacityButton>
            );
          })}
        </Box> */}
        <Box display="flex" flexDirection="column" alignItems="center" pt={3}>
          <Typography pb={3} fontWeight={600} variant="h5">
            Preview
          </Typography>
          <PhonePreview>
            <TemplateHome
              mode="preview"
              fontColor={FONT_COLOR}
              color={businessTemplateData.color}
              logoSignedURL={companyV2Detail?.logoSignedURL}
              name={companyV2Detail?.name}
              description={companyV2Detail?.description}
              socialMedia={companyV2Detail?.socialMedia || {}}
              productDetails={companyV2Detail?.productDetails || []}
              currency={companyV2Detail?.currency}
            />
          </PhonePreview>
        </Box>
        <Box px={2} py={5} display="flex" flexDirection="row" gap={2}>
          <CustomButton
            variant="outlined"
            onClick={() =>
              setBusinessTemplateData({
                color: companyV2Detail?.color || TEMPLATE_HEX_COLOR[0].primary,
                templateId: (companyV2Detail?.template ||
                  TEMPLATE_PAGE[0].id) as PageTemplateType,
              })
            }
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={updateCompanyV2Mutation.isLoading}
            onClick={handleUpdateColor}
          >
            Terapkan
          </CustomButton>
        </Box>
      </Box>
    );
  };

  return (
    <MobileContainer>
      <Box>
        <Box sx={{ backgroundColor: COLOR.primary500, pb: 2 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            ml={2}
          >
            <ProfileAvatar
              imgProps={{
                src: companyV2Detail?.logoSignedURL || NeemaLogoPNG,
              }}
              name={companyV2Detail?.name || ""}
              url={companyV2Detail?.companyDomainName || ""}
              isLoading={companyV2Query.isLoading}
            />
            <OpacityButton
              onClick={() =>
                navigate(
                  RoutePath[ROUTE_NAME.EDIT_BUSINESS] + `/${companyId}/edit`
                )
              }
              sx={{
                pl: 1,
                py: 2,
                pr: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <GearIcon color="white" />
            </OpacityButton>
          </Box>
        </Box>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            label="Produk Saya"
            value="profile"
            icon={<FaStore />}
            iconPosition="start"
          />
          <Tab
            label="Ubah Tampilan"
            value="design"
            icon={<FaPalette />}
            iconPosition="start"
          />
        </Tabs>
        {tabValue === TAB_ENUM.DESIGN && _renderEditDesign()}
        {tabValue === TAB_ENUM.PROFILE && _renderProfile()}
      </Box>
      <ChooseProductTypeBottomSheet
        open={openProductSheet}
        onClick={(type) => {
          switch (type) {
            case PRODUCT_TYPE.LINK:
              navigate(NEW_PRODUCT_NAV.LINK);
              return;
            case PRODUCT_TYPE.RESERVATION:
              navigate(NEW_PRODUCT_NAV.RESERVATION);
              return;
          }
        }}
        bottomSheetProps={{
          onDismiss: () => {
            setOpenProductSheet(false);
          },
        }}
      />
      <SectionBottomSheet
        open={openSectionSheet}
        onClick={async (name: string) => {
          // add new section
          if (editSectionProductID === "new") {
            await createProductSectionMutation.mutateAsync({
              companyID: companyId || "",
              name: name || "New Section",
            });
          } else {
            await updateProductSectionMutation.mutateAsync({
              productId: editSectionProductID,
              companyID: companyId || "",
              name: name || "New Section",
            });
          }
          setOpenSectionSheet(false);
          setEditSectionProductID("new");
          await companyV2Query.refetch();
        }}
        bottomSheetProps={{
          onDismiss: () => {
            setOpenSectionSheet(false);
          },
        }}
        isEditSection={editSectionProductID !== "new"}
        color={businessTemplateData.color}
        initialName={editSectionProductName}
      />
    </MobileContainer>
  );
};

export default Business;
