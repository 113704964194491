import React, { useMemo, useEffect } from "react";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import { Box, Typography, Skeleton, Alert } from "@mui/material";
import { useGetUserCompanies } from "../../query/queries";
import { Navigate, useNavigate } from "react-router-dom";
import { COOKIE, ROUTE_NAME, RoutePath } from "../../utils/constant";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import AvatarImg from "../../components/AvatarImg/AvatarImg";
import { setUserData } from "../../redux/reducers/user";
import { UserCompaniesType } from "../../api/request.types";
import { useDispatch } from "react-redux";
import AppContainer from "../../components/Layout/AppContainer/AppContainer";
import CustomButton from "../../components/CustomButton/CustomButton";
import { clearAll } from "../../redux/actions";
import Cookies from "js-cookie";
import NeemaLogoPNG from "../../assets/images/neema-logo.png";

const ChooseCompany = () => {
  const { data, isLoading, isFetched, isError, error } = useGetUserCompanies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const businessList = useMemo(() => {
    return data?.data?.data || [];
  }, [data]);

  useEffect(() => {
    if (isFetched) {
      if (businessList.length === 0) {
        navigate(RoutePath[ROUTE_NAME.CREATE_BUSINESS], { replace: true });
      } else if (businessList.length === 1) {
        dispatch(setUserData({ companyId: businessList[0].companyID }));
        navigate(RoutePath[ROUTE_NAME.HOME], { replace: true });
      }
    }
  }, [isFetched, businessList, dispatch, navigate]);

  const handleOnClickCompany = (business: UserCompaniesType) => () => {
    dispatch(setUserData({ companyId: business.companyID }));
    navigate(RoutePath[ROUTE_NAME.HOME]);
  };

  if (isLoading) {
    return (
      <AppContainer>
        <Box py={3} px={2}>
          <Typography variant="h4" fontWeight="bold">
            Pilih Usaha Mu
          </Typography>
          <Box py={3}>
            {Array.from({ length: 3 }).map((_, idx) => (
              <Skeleton
                key={idx}
                variant="rectangular"
                width="100%"
                height={120}
                sx={{ mb: 2 }}
              />
            ))}
          </Box>
        </Box>
      </AppContainer>
    );
  }

  if (isError) {
    return (
      <AppContainer>
        <Box py={3} px={2}>
          <Alert severity="error">
            Failed to fetch companies. Please try again later.
          </Alert>
        </Box>
        <CustomButton
          onClick={() => {
            Cookies.remove(COOKIE.TOKEN, { path: "/" });
            dispatch(clearAll());
          }}
        >
          Keluar
        </CustomButton>
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <Box py={3}>
        <Typography px={2} variant="h4" fontWeight="bold">
          Pilih Usaha Mu
        </Typography>
        <Box display="flex" flexDirection="column" py={3}>
          {businessList.length > 0 ? (
            businessList.map((business) => (
              <OpacityButton
                key={business.companyID}
                onClick={handleOnClickCompany(business)}
                sx={{
                  wordBreak: "break-all",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "120px",
                  px: 2,
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                <AvatarImg
                  imgProps={{
                    src: business.logoSignedURL || NeemaLogoPNG,
                    alt: business.name,
                  }}
                  size={80}
                  sx={{ mr: 2 }}
                />
                <Typography variant="h6" fontWeight="500">
                  {business.name}
                </Typography>
              </OpacityButton>
            ))
          ) : (
            <Typography px={2} py={2} variant="body1">
              You don’t have any businesses yet. Please create one.
            </Typography>
          )}
        </Box>
      </Box>
    </AppContainer>
  );
};

export default ChooseCompany;
