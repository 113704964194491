import Joi from "joi";

export const loginSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().min(5).max(20).required(),
});

export const registerUserSchema = Joi.object({
  name: Joi.string().min(3).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().min(5).max(20).required(),
});
export const resetPasswordSchema = Joi.object({
  current: Joi.string().min(5).max(20).required().label("Current password"),
  new: Joi.string().min(5).max(20).required().label("New password"),
  retype: Joi.string()
    .valid(Joi.ref("new"))
    .required()
    .label("Retype new password")
    .messages({ "any.only": "Passwords must match" }),
});

export const forgotPasswordSchema = Joi.object({
  new: Joi.string().min(5).max(20).required().label("New password"),
  retype: Joi.string()
    .valid(Joi.ref("new"))
    .required()
    .label("Retype new password")
    .messages({ "any.only": "Passwords must match" }),
});
export const requestForgotPasswordSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});
