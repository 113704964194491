import React from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { COOKIE, ROUTE_NAME, RoutePath } from '../../utils/constant';
import { clearAll } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton/CustomButton';
import MobileContainer from '../../components/Layout/MobileContainer/MobileContainer';
import { useLogout } from '../../query/mutations';
import Cookies from 'js-cookie';
import { Box, CircularProgress } from '@mui/material';
import { useFindUserById } from '../../query/queries';
import { RootReducerState } from '../../redux/reducers';
import { COLOR } from '../../utils/color';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutQuery = useLogout();
  const userReducer = useSelector((state: RootReducerState) => state.userReducer);
  const getUserProfileById = useFindUserById(userReducer.data.userId || "");
  const userProfile = getUserProfileById.data?.data?.data;
  const logoutUser = () => {
    if (Cookies.get(COOKIE.TOKEN)) {
      logoutQuery.mutate(undefined, {
        onSuccess: () => {
          dispatch(clearAll());
          Cookies.remove(COOKIE.TOKEN, { path: '/' });
          navigate(RoutePath.LOGIN, { replace: true });
        }
      });
    } else {
      navigate(RoutePath.LOGIN, { replace: true });
    }
  };

  return (
    <MobileContainer>
      <Box px={2} display="flex" justifyContent="center" flexDirection="column" width="100%" height="100%" gap={3}>
        <Box py={2} px={2} border={`1px solid ${COLOR.neutral300}`} sx={{ borderRadius: 5, wordBreak: 'break-all' }}>
          {
            getUserProfileById.isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap={2}>
                  <Typography>Nama: </Typography><Typography fontSize={14}>{userProfile?.fullName}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap={2}>
                  <Typography>Email: </Typography><Typography fontSize={14}>{userProfile?.email}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" gap={2}>
                  <Typography>No Hp: </Typography><Typography fontSize={14}>{userProfile?.phoneNumber || "-"}</Typography>
                </Box>
              </>
            )
          }
        </Box>
        <CustomButton
          variant="outlined"
          onClick={() => {
            navigate(RoutePath[ROUTE_NAME.RESET_PASSWORD]);
          }}
        >
          Reset Password
        </CustomButton>
        <CustomButton
          disabled={logoutQuery.isLoading}
          onClick={() => {
            logoutUser();
          }}
        >
          {logoutQuery.isLoading ? 'Loading...' : 'Logout'}
        </CustomButton>

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={3}>
          <Typography variant='caption'>Powered By</Typography>
          <Box height="24px" display="flex" justifyContent="center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/neema-b7c3b.appspot.com/o/public-web-assets%2FLogo.png?alt=media&token=3ddd51e9-0144-40a6-a29e-6e23f91cda8c"
              alt="neema-logo"
              className="img-default"
              style={{ width: 'fit-content' }}
            />
          </Box>
        </Box>
      </Box>
    </MobileContainer>
  );
};

export default Profile;
