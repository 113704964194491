import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import {
  downloadSignedUrl,
  findUserByIdAPI,
  getCheckoutDetailByProductId,
  getCompanyById,
  getCompanyByIdV2,
  getDataByDomainName,
  getDomainAvailabiity,
  getProductById,
  getProductCompactList,
  getProductLinkById,
  getProductReservationById,
  getProductReservationCompactList,
  getPublicCompanyDomainV2,
  getPublicProductById,
  getPublicProductReservationByMonth,
  getPublicProductScheduleById,
  getPublicProductScheduleByIdV2,
  getPublicTransactionId,
  getScheduleDetailsByProductId,
  getScheduleDetailsByProductIdV2,
  getTransactionById,
  getTransactionByQuery,
  getUserCompanies,
  totalProductByTransaction,
  totalTransactionByStatus,
  totalTransactionPerDate,
  transactionOverview,
  upcomingSchedules,
} from "../api/request";
import { AxiosResponse } from "axios";
import {
  APICheckoutDetailByProductIdResponse,
  APIGetByDomainNameResponse,
  APIGetCompanyV2Response,
  APIGetDomainAvailabilityResponse,
  APIGetProductByIdResponse,
  APIGetProductCompactListResponse,
  APIGetProductLinkByIdResponse,
  APIGetProductReservationByIdResponse,
  APIGetPublicProductById,
  APIGetPublicProductScheduleDetailByIdResponse,
  APIGetPublicTransactionByIdResponse,
  APIGetScheduleDetailsByProductIdResponse,
  APIGetTransactionByIdResponse,
  APIGetTransactionQueries,
  APIGetTransactionResponse,
  APIGetUpcomingSchedulesResponse,
  APIProductByTransactionResponse,
  APITotalTransactionByDateResponse,
  APITransactionByStatusResponse,
  APITransactionOverviewResponse,
  CompanyByIdResponse,
  DashboardBaseQuery,
  DownloadSignedUrlResponse,
  UpcomingScheduleQuery,
  UserCompaniesResponse,
} from "../api/request.types";

export type QueryResult<ResponseType, ErrorType = unknown> = UseQueryResult<
  AxiosResponse<ResponseType>,
  ErrorType
>;
export type QueryOptions<ResponseType, ErrorType = unknown> = UseQueryOptions<
  AxiosResponse<ResponseType>,
  ErrorType
>;

export const useFindUserById = (userId: string) => {
  return useQuery(["findUserById", userId], () => findUserByIdAPI(userId), {
    enabled: !!userId,
    staleTime: 1000 * 60 * 5,
  });
};

export const useGetCompanyById = (
  companyId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<CompanyByIdResponse, any>,
          unknown,
          AxiosResponse<CompanyByIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getCompanyById", companyId],
    () => getCompanyById(companyId),
    {
      ...queryProps,
      enabled: !!companyId,
    }
  );
};
export const useGetUserCompanies = (
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<UserCompaniesResponse, any>,
          unknown,
          AxiosResponse<UserCompaniesResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(["getUserCompanies"], () => getUserCompanies(), {
    ...queryProps,
  });
};

export const useGetDataByDomainName = (
  domainName: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetByDomainNameResponse, any>,
          unknown,
          AxiosResponse<APIGetByDomainNameResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getByDomainName", domainName],
    () => getDataByDomainName(domainName),
    {
      ...queryProps,
      enabled: !!domainName,
    }
  );
};

export const useGetDomainAvailability = (
  domainName: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetDomainAvailabilityResponse, any>,
          unknown,
          AxiosResponse<APIGetDomainAvailabilityResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getDomainAvailability", domainName],
    () => getDomainAvailabiity(domainName),
    {
      ...queryProps,
      enabled: !!domainName,
    }
  );
};

export const useGetPublicProductById = (
  productId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetPublicProductById, any>,
          unknown,
          AxiosResponse<APIGetPublicProductById, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getProductById", productId],
    () => getPublicProductById(productId),
    {
      ...queryProps,
    }
  );
};

export const useDownloadSignedUrl = (
  path: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<DownloadSignedUrlResponse, any>,
          unknown,
          AxiosResponse<DownloadSignedUrlResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(["downloadSignedUrl", path], () => downloadSignedUrl(path), {
    ...queryProps,
  });
};

// date format: YYYY-MM-DD
export const useGetPublicProductScheduleById = (
  productId: string,
  date: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetPublicProductScheduleDetailByIdResponse, any>,
          unknown,
          AxiosResponse<APIGetPublicProductScheduleDetailByIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getPublicProductScheduleById", productId, date],
    () => getPublicProductScheduleById(productId, date),
    {
      ...queryProps,
    }
  );
};

// date format: YYYY-MM-DD
export const useGetPublicProductScheduleByIdV2 = (
  productId: string,
  date: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetPublicProductScheduleDetailByIdResponse, any>,
          unknown,
          AxiosResponse<APIGetPublicProductScheduleDetailByIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetPublicProductScheduleByIdV2", productId, date],
    () => getPublicProductScheduleByIdV2(productId, date),
    {
      ...queryProps,
    }
  );
};

// date format: YYYY-MM-DD
export const useGetScheduleDetailByProductIdV2 = (
  productId: string,
  date: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetScheduleDetailsByProductIdResponse, any>,
          unknown,
          AxiosResponse<APIGetScheduleDetailsByProductIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getScheduleDetailsByProductIdV2", productId, date],
    () => getScheduleDetailsByProductIdV2(productId, date),
    {
      ...queryProps,
      enabled: !!productId && !!date,
      refetchInterval: 300000, // 5 minutes
    }
  );
};
// date format: YYYY-MM-DD
export const useGetScheduleDetailByProductId = (
  productId: string,
  date: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetScheduleDetailsByProductIdResponse, any>,
          unknown,
          AxiosResponse<APIGetScheduleDetailsByProductIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getScheduleDetailsByProductId", productId, date],
    () => getScheduleDetailsByProductId(productId, date),
    {
      ...queryProps,
      enabled: !!productId && !!date,
      refetchInterval: 300000, // 5 minutes
    }
  );
};

export const useGetProductCompactList = (
  companyId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetProductCompactListResponse, any>,
          unknown,
          AxiosResponse<APIGetProductCompactListResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getProductCompactList", companyId],
    () => getProductCompactList(companyId),
    {
      ...queryProps,
      enabled: !!companyId,
    }
  );
};
export const useGetProductReservationCompactList = (
  companyId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetProductCompactListResponse, any>,
          unknown,
          AxiosResponse<APIGetProductCompactListResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getProductReservationCompactList", companyId],
    () => getProductReservationCompactList(companyId),
    {
      ...queryProps,
      enabled: !!companyId,
    }
  );
};

export const useGetPublicTransactionById = (
  companyDomainName: string,
  transactionId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetPublicTransactionByIdResponse, any>,
          unknown,
          AxiosResponse<APIGetPublicTransactionByIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["getTransactionById", companyDomainName, transactionId],
    () => getPublicTransactionId(companyDomainName, transactionId),
    {
      ...queryProps,
      enabled: !!companyDomainName && !!transactionId,
    }
  );
};

export const useGetTransactionByQuery = (
  query: APIGetTransactionQueries,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetTransactionResponse, any>,
          unknown,
          AxiosResponse<APIGetTransactionResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    [
      "getTransactionListByQuery",
      `compId_${query.companyID}`,
      `end_${query?.startDate}` || `end_${query?.endDate}` || "",
      `stat_${query?.status}`,
      `prod_${query?.productIDs}`,
    ],
    () => getTransactionByQuery(query),
    {
      ...queryProps,
      enabled: !!query.companyID,
    }
  );
};

export const useGetProductByProductId = (
  productId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetProductByIdResponse, any>,
          unknown,
          AxiosResponse<APIGetProductByIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetProductByProductId", productId],
    () => getProductById(productId),
    {
      ...queryProps,
      enabled: !!productId,
    }
  );
};

export const useGetTransactionById = (
  transactionId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetTransactionByIdResponse, any>,
          unknown,
          AxiosResponse<APIGetTransactionByIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetTransactionById", transactionId],
    () => getTransactionById(transactionId),
    {
      ...queryProps,
      enabled: !!transactionId,
    }
  );
};
export const useGetCompanyByIdV2 = (
  companyId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetCompanyV2Response, any>,
          unknown,
          AxiosResponse<APIGetCompanyV2Response, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetCompanyByIdV2", companyId],
    () => getCompanyByIdV2(companyId),
    {
      ...queryProps,
      enabled: !!companyId,
    }
  );
};

export const useGetPublicCompanyDomainV2 = (
  companyDomainName: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetCompanyV2Response, any>,
          unknown,
          AxiosResponse<APIGetCompanyV2Response, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetPublicCompanyDomainV2", companyDomainName],
    () => getPublicCompanyDomainV2(companyDomainName),
    {
      ...queryProps,
      enabled: !!companyDomainName,
    }
  );
};

export const useGetPublicProductReservationByMonth = (
  productId: string,
  monthYear: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<string[], any>,
          unknown,
          AxiosResponse<string[], any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetPublicProductReservationByMonth", productId, monthYear],
    () => getPublicProductReservationByMonth(productId, monthYear),
    {
      ...queryProps,
      enabled: !!productId && !!monthYear,
    }
  );
};
export const useGetProductReservationByIdV2 = (
  productId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetProductReservationByIdResponse, any>,
          unknown,
          AxiosResponse<APIGetProductReservationByIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetProductReservationByIdV2", productId],
    () => getProductReservationById(productId),
    {
      ...queryProps,
    }
  );
};

export const useGetProductLinkByIdV2 = (
  productId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetProductLinkByIdResponse, any>,
          unknown,
          AxiosResponse<APIGetProductLinkByIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetProductLinkByIdV2", productId],
    () => getProductLinkById(productId),
    {
      ...queryProps,
    }
  );
};

export const useGetCheckoutDetailById = (
  productId: string,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APICheckoutDetailByProductIdResponse, any>,
          unknown,
          AxiosResponse<APICheckoutDetailByProductIdResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetCheckoutDetailById", productId],
    () => getCheckoutDetailByProductId(productId),
    {
      ...queryProps,
      enabled: !!productId,
    }
  );
};

export const useGetTotalTransactionPerDate = (
  data: DashboardBaseQuery,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APITotalTransactionByDateResponse, any>,
          unknown,
          AxiosResponse<APITotalTransactionByDateResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetTotalTransactionPerDate", data.startDate, data.endDate],
    () => totalTransactionPerDate(data),
    {
      ...queryProps,
      enabled: !!data.startDate && !!data.endDate,
    }
  );
};

export const useGetTransactionOverview = (
  data: DashboardBaseQuery,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APITransactionOverviewResponse, any>,
          unknown,
          AxiosResponse<APITransactionOverviewResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetTransactionOverview", data.startDate, data.endDate],
    () => transactionOverview(data),
    {
      ...queryProps,
      enabled: !!data.startDate && !!data.endDate,
    }
  );
};

export const useGetTransactionByStatus = (
  data: DashboardBaseQuery,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APITransactionByStatusResponse, any>,
          unknown,
          AxiosResponse<APITransactionByStatusResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetTransactionByStatus", data.startDate, data.endDate],
    () => totalTransactionByStatus(data),
    {
      ...queryProps,
      enabled: !!data.startDate && !!data.endDate,
    }
  );
};

export const useGetProductByTransaction = (
  data: DashboardBaseQuery,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIProductByTransactionResponse, any>,
          unknown,
          AxiosResponse<APIProductByTransactionResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetProductByTransaction", data.startDate, data.endDate],
    () => totalProductByTransaction(data),
    {
      ...queryProps,
      enabled: !!data.startDate && !!data.endDate,
    }
  );
};

export const useGetUpcomingSchedules = (
  data: UpcomingScheduleQuery,
  queryProps?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<APIGetUpcomingSchedulesResponse, any>,
          unknown,
          AxiosResponse<APIGetUpcomingSchedulesResponse, any>,
          string[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery(
    ["useGetUpcomingSchedules", data.startDate],
    () => upcomingSchedules(data),
    {
      ...queryProps,
      enabled: !!data.startDate,
    }
  );
};
