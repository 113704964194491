import imageCompression from "browser-image-compression";

export const toKB = (mb: number = 5) => mb * 1024 * 1024;
export const MAX_FILE_SIZE_IN_MB: number = toKB(10);

export const compressImage = async (
  file: File,
  maxSizeMB: number = 1,
  maxWidthOrHeight: number = 1920
) => {
  const options = {
    maxSizeMB,
    maxWidthOrHeight,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Error compressing the file:", error);
    throw error;
  }
};

export const isValidFileSize = (
  file: File,
  maxSize: number = MAX_FILE_SIZE_IN_MB
) => {
  return file.size <= maxSize;
};
