import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";
import { SX_P_M_SIZE } from "../../utils/constant";

export const ImageButtonStyle: SxProps<Theme> = {
  mt: 5,
  position: "relative",
};


export const BusinessImageContainerStyle: SxProps<Theme> = {
  width: 100,
  height: 100,
  borderRadius: "50%",
  overflow: "hidden",
  display: 'flex',
  backgroundColor: COLOR.neutral200,
  justifyContent: 'center',
  alignItems: 'center',
};
export const BusinessImageEditStyle: SxProps<Theme> = {
  position: "absolute",
  width: 36,
  height: 36,
  borderRadius: "50%",
  backgroundColor: COLOR.primary500,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: 0,
  right: 0,
};

export const DeleteBtnSx: SxProps<Theme> = {
  width: 'fit-content',
  height: 'fit-content',
  aspectRatio: 1,
  minWidth: '50px',
  px: 0,
  backgroundColor: COLOR.danger50,
  color: COLOR.danger500,
  "&:hover": { backgroundColor: COLOR.danger50 },
  "&:active": { backgroundColor: COLOR.danger50 },
}

export const EditBtnSx: SxProps<Theme> = {
  width: 'fit-content',
  height: 'fit-content',
  backgroundColor: COLOR.primary50,
  aspectRatio: 1,
  minWidth: '50px',
  px: 0,
};
