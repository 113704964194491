import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Box, Typography } from "@mui/material";
import {
  DEFAULT_SNACKBAR_PROPS,
  PRODUCT_TYPE,
  ROUTE_NAME,
  RoutePath,
  THUMB_STYLE,
} from "../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import NumberedTitle from "../../../components/NumberedTitle/NumberedTitle";
import { fileToBase64 } from "../../../utils/helper";
import { COLOR } from "../../../utils/color";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import {
  initialState,
  setLinkData,
  resetProductLinkData,
} from "../../../redux/reducers/product";
import { FieldContainer, PreviewContainer } from "./Product.styles";
import { enqueueSnackbar } from "notistack";
import SingleImageUploader from "../../../components/SingleImageUploader/SingleImageUploader";
import ImageCropDialog from "../../../components/ImageCropDialog/imageCropDialog";
import {
  useGetCompanyByIdV2,
  useGetProductLinkByIdV2,
} from "../../../query/queries";
import ThumbnailButton from "../../../components/ThumbnailButton/ThumbnailButton";
import dayjs from "dayjs";
import { compressImage } from "../../../utils/image";
import {
  useCreateProductLink,
  useDeleteProductById,
  useUpdateProductLinkV2,
  useUploadToStorageMutation,
} from "../../../query/mutations";
import { errorLogger } from "../../../utils/logger";
import ProductFooter from "../../../components/Footer/ProductFooter";
import { openDialog } from "../../../redux/reducers/confirmationDialog";
import { useNavigate } from "react-router-dom";
import BoxStyle from "../../../components/BoxStyle/BoxStyle";
import { RxButton } from "react-icons/rx";
import { BiSolidMessageDetail } from "react-icons/bi";

type ImgCropStateType = {
  img?: string;
  open: boolean;
};
interface LinkProductPropsI {
  productType: PRODUCT_TYPE;
  productId: string;
}
const LinkProduct = (props: LinkProductPropsI) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageCropState, setImageCropState] = useState<ImgCropStateType>({
    img: undefined,
    open: false,
  });
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const { productLinkData } = useSelector(
    (state: RootReducerState) => state.productReducer
  );
  const companyId = userReducer.data.companyId;
  const companyV2Query = useGetCompanyByIdV2(companyId || "");
  const companyDetail = useMemo(() => {
    return companyV2Query.data?.data?.data;
  }, [companyV2Query.data?.data?.data]);

  const productV2Query = useGetProductLinkByIdV2(props.productId || "");
  const productDetail = useMemo(() => {
    return productV2Query.data?.data?.data;
  }, [productV2Query.data?.data?.data]);
  const deleteProductByIdMutation = useDeleteProductById();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadToStorageMutation = useUploadToStorageMutation();
  const createProductLinkMutation = useCreateProductLink();
  const updateProductLinkMutation = useUpdateProductLinkV2();

  const handleCropClose = () => {
    setImageCropState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleCropComplete = async (croppedImg: string) => {
    try {
      if (!companyId) return;
      const blob = await fetch(croppedImg).then((res) => res.blob());
      const croppedFile = new File(
        [blob],
        `product-${dayjs().toISOString()}.jpg`,
        { type: blob.type }
      );
      const response = await uploadToStorageMutation.mutateAsync({
        companyId,
        image: croppedFile,
        usage: "product",
      });
      const imgUrl = response.data.data;
      dispatch(setLinkData({ thumbImg: imgUrl[0] }));
      handleCropClose();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleCreateProductLink = async (status: "active" | "draft") => {
    await createProductLinkMutation.mutateAsync({
      companyID: companyId || "",
      thumbStyle: productLinkData.thumbStyle,
      thumbImg: productLinkData.thumbImg,
      title:
        productLinkData.thumbStyle === THUMB_STYLE.BUTTON
          ? productLinkData.buttonText
          : productLinkData.title,
      subtitle: productLinkData.subtitle,
      buttonText: productLinkData.buttonText,
      link: productLinkData.link,
      status: status,
    });
    dispatch(resetProductLinkData());
    navigate(`${RoutePath[ROUTE_NAME.BUSINESS]}?tab=profile`, {
      replace: true,
    });
  };

  const handleDeleteProduct = async () => {
    try {
      await deleteProductByIdMutation.mutateAsync({
        productId: props.productId,
        type: props.productType,
      });
      enqueueSnackbar(
        `Product "${productLinkData?.title || ""}" deleted successfully`,
        {
          variant: "success",
          ...DEFAULT_SNACKBAR_PROPS,
        }
      );
      await companyV2Query.refetch();
      navigate(RoutePath[ROUTE_NAME.BUSINESS], { replace: true });
    } catch (error) {
      errorLogger(error);
      enqueueSnackbar(
        "Gagal menghapus product. mohon coba beberapa saat lagi.",
        {
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
        }
      );
    }
  };

  const handleUpdateProductLink = async (status: "active" | "draft") => {
    await updateProductLinkMutation.mutateAsync({
      productId: props.productId,
      thumbStyle: productLinkData.thumbStyle,
      thumbImg: productLinkData.thumbImg,
      title:
        productLinkData.thumbStyle === THUMB_STYLE.BUTTON
          ? productLinkData.buttonText
          : productLinkData.title,
      subtitle: productLinkData.subtitle,
      buttonText: productLinkData.buttonText,
      link: productLinkData.link,
      status: status,
    });
    dispatch(resetProductLinkData());
    navigate(`${RoutePath[ROUTE_NAME.BUSINESS]}?tab=profile`, {
      replace: true,
    });
  };
  const initializeData = useCallback(() => {
    if (props.productId === "new") {
      dispatch(setLinkData({ ...initialState.productLinkData }));
    } else {
      dispatch(setLinkData(productDetail || initialState.productLinkData));
    }
  }, [dispatch, productDetail, props.productId]);
  useEffect(initializeData, [initializeData]);

  const handleOnChangeImg = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target instanceof HTMLInputElement && e.target.files) {
      const file = e.target.files[0];
      if (file) {
        try {
          const compressedImg = await compressImage(file);
          const base64Image = await fileToBase64(compressedImg);
          setImageCropState((prev) => ({
            ...prev,
            open: true,
            img: base64Image,
          }));
        } catch (error) {
          enqueueSnackbar("Error Preview Image", {
            ...DEFAULT_SNACKBAR_PROPS,
            variant: "error",
          });
        }
      }
    }
  };

  return (
    <Box>
      <Box py={2} display="flex" flexDirection="column" gap={2}>
        <Box sx={FieldContainer} mt={2} mb={5}>
          <NumberedTitle num={1} text="Pilih Gaya" />
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <BoxStyle
              selected={productLinkData.thumbStyle === THUMB_STYLE.BUTTON}
              onClick={() => {
                dispatch(
                  setLinkData({
                    thumbStyle: THUMB_STYLE.BUTTON,
                  })
                );
              }}
            >
              <RxButton size={20} />
              <Typography>Button</Typography>
            </BoxStyle>
            <BoxStyle
              selected={productLinkData.thumbStyle === THUMB_STYLE.CALLOUT}
              onClick={() => {
                dispatch(
                  setLinkData({
                    thumbStyle: THUMB_STYLE.CALLOUT,
                  })
                );
              }}
            >
              <BiSolidMessageDetail size={20} />
              <Typography>Callout</Typography>
            </BoxStyle>
          </Box>
          <Typography variant="caption" color={COLOR.neutral500}>
            Gaya dapat dilihat pada bagian paling bawah
          </Typography>
        </Box>

        <Box sx={FieldContainer}>
          <NumberedTitle num={1} text="Masukkan Link" />
          <TextInput
            textInputProps={{
              placeholder: "https://....",
              value: productLinkData.link,
              onChange: (e) => {
                dispatch(
                  setLinkData({
                    link: e.target.value,
                  })
                );
              },
            }}
          />
        </Box>

        <Box sx={FieldContainer}>
          <NumberedTitle num={2} text="Pilih Gambar" />
          <Box mb={5}>
            <SingleImageUploader
              fileInputRef={fileInputRef}
              img={productLinkData.thumbImg}
              onChange={handleOnChangeImg}
              onClickRemove={() => {
                dispatch(
                  setLinkData({
                    thumbImg: initialState.productLinkData.thumbImg,
                  })
                );
              }}
            />
          </Box>
        </Box>
        <Box sx={FieldContainer}>
          <NumberedTitle num={3} text="Tambah Text" />
          <Box display="flex" flexDirection="column" gap={2}>
            {productLinkData.thumbStyle !== THUMB_STYLE.BUTTON && (
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography fontWeight={600}>Title</Typography>
                  <Typography color={COLOR.neutral400} variant="caption">
                    {productLinkData.title.length}/50
                  </Typography>
                </Box>
                <TextInput
                  textInputProps={{
                    value: productLinkData.title,
                    onChange: (e) => {
                      if (e.target.value.length > 50) return;
                      dispatch(
                        setLinkData({
                          title: e.target.value,
                        })
                      );
                    },
                  }}
                />
              </Box>
            )}

            {productLinkData.thumbStyle !== THUMB_STYLE.BUTTON && (
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography fontWeight={600}>Subtitle</Typography>
                  <Typography color={COLOR.neutral400} variant="caption">
                    {productLinkData.subtitle.length}/100
                  </Typography>
                </Box>
                <TextInput
                  textInputProps={{
                    value: productLinkData.subtitle,
                    onChange: (e) => {
                      if (e.target.value.length > 100) return;
                      dispatch(
                        setLinkData({
                          subtitle: e.target.value,
                        })
                      );
                    },
                  }}
                />
              </Box>
            )}

            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography fontWeight={600}>Button</Typography>
                <Typography color={COLOR.neutral400} variant="caption">
                  {productLinkData.buttonText.length}/30
                </Typography>
              </Box>
              <TextInput
                textInputProps={{
                  value: productLinkData.buttonText,
                  onChange: (e) => {
                    if (e.target.value.length > 30) return;
                    dispatch(
                      setLinkData({
                        buttonText: e.target.value,
                      })
                    );
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          pt={1}
          pb={2}
          px={2}
        >
          <Typography pb={1} fontWeight={600} variant="h5">
            Preview
          </Typography>
          <Box
            sx={{
              ...PreviewContainer,
              backgroundColor: companyDetail?.color || COLOR.primary500,
            }}
          >
            <ThumbnailButton
              type={productLinkData.thumbStyle}
              primaryColor={companyDetail?.color || COLOR.primary500}
              baseColor={"black"}
              data={{
                img: productLinkData.thumbImg,
                title: productLinkData.title,
                subtitle: productLinkData.subtitle,
                buttonText: productLinkData.buttonText,
                currency: companyDetail?.currency || "IDR",
                selectedDate: dayjs(),
              }}
            />
          </Box>
        </Box>
        <ImageCropDialog
          imageUrl={imageCropState?.img}
          open={!!imageCropState?.open}
          onClose={handleCropClose}
          onCropComplete={handleCropComplete}
        />
      </Box>

      <ProductFooter
        onClickTrash={() => {
          dispatch(
            openDialog({
              title: "Apakah anda yakin?",
              message: "Apakah anda yakin untuk menghapus produk ini?",
              primaryBtn: {
                text: "Tidak",
                onClick: () => {},
              },
              secondaryBtn: {
                text: "Ya, Saya Yakin",
                onClick: () => {
                  handleDeleteProduct();
                },
              },
            })
          );
        }}
        onClickDraft={() => {
          props.productId !== "new"
            ? handleUpdateProductLink("draft")
            : handleCreateProductLink("draft");
        }}
        onClickPublish={() => {
          props.productId !== "new"
            ? handleUpdateProductLink("active")
            : handleCreateProductLink("active");
        }}
        publishDisabled={productLinkData.link.length === 0}
        productId={props.productId}
        productStatus={productDetail?.status || "active"}
      />
    </Box>
  );
};

export default LinkProduct;
