import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Slider, Typography } from '@mui/material';
import Cropper, { Area } from 'react-easy-crop';
import { getCroppedImg } from './cropImage';

interface ImageCropDialogProps {
  imageUrl?: string; 
  open: boolean;
  onClose: () => void;
  onCropComplete: (croppedImage: string) => void;
  aspectRatio?: { width: number; height: number }; 
  title?: string;
  errMsg?: string;
}

const ImageCropDialog: React.FC<ImageCropDialogProps> = ({
  imageUrl = "",
  open,
  onClose,
  onCropComplete,
  aspectRatio = { width: 1, height: 1 },
  title = "Crop Gambar",
  errMsg = "Gambar tidak terdeteksi. Mohon periksa url gambar yang kamu berikan."
}) => {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropChange = useCallback((newCrop: { x: number; y: number }) => {
    setCrop(newCrop);
  }, []);

  const onZoomChange = useCallback((newZoom: number) => {
    setZoom(newZoom);
  }, []);

  const onCropCompleteHandler = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (croppedAreaPixels && imageUrl) {
      try {
        const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
        onCropComplete(croppedImage);
      } catch (e) {
        console.error('Error cropping the image', e);
      }
    } else {
      onCropComplete(imageUrl);
    }
    onClose();
  };

  const handleReset = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {imageUrl ? (
          <>
            <div style={{ position: 'relative', width: '100%', height: 400 }}>
              <Cropper
                image={imageUrl}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio.width / aspectRatio.height}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={onCropCompleteHandler}
              />
            </div>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoomValue) => onZoomChange(zoomValue as number)}
            />
          </>
        ) : (
          <Typography color="error" align="center">
            {errMsg}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="secondary" disabled={!imageUrl}>
          Reset Crop
        </Button>
        <Button onClick={handleCrop} color="primary" variant="contained" disabled={!imageUrl}>
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropDialog;
